export const dataCuentaPublicaNew = [
  {
    id: 1,
    year: "2014",
    title: "Cuenta Pública 2014",
    link: "cuentaPub/1",
  },
  {
    id: 2,
    year: "2015",
    title: "Cuenta Pública 2015",
    link: "cuentaPub/2",
  },
  {
    id: 1,
    year: "2016",
    title: "Cuenta Pública 1er Trimestre",
    link: "cuentaPub2016/1",
  },
  {
    id: 2,
    year: "2016",
    title: "Cuenta Pública 2do Trimestre",
    link: "cuentaPub2016/2",
  },
  {
    id: 3,
    year: "2016",
    title: "Cuenta Pública 3er Trimestre",
    link: "cuentaPub2016/3",
  },
  {
    id: 4,
    year: "2016",
    title: "Cuenta Pública 4to Trimestre",
    link: "cuentaPub2016/4",
  },
  {
    id: 5,
    year: "2017",
    title: "Cuenta Pública 1er Trimestre",
    link: "cuentaPub2017/1",
  },
  {
    id: 6,
    year: "2017",
    title: "Cuenta Pública 2do Trimestre",
    link: "cuentaPub2017/2",
  },
  {
    id: 7,
    year: "2017",
    title: "Cuenta Pública 3er Trimestre",
    link: "cuentaPub2017/3",
  },
  {
    id: 8,
    year: "2017",
    title: "Cuenta Pública 4to Trimestre",
    link: "cuentaPub2017/4",
  },
  {
    id: 9,
    year: "2018",
    title: "Cuenta Pública 1er Trimestre",
    link: "cuentaPub2018/1",
  },
  {
    id: 10,
    year: "2018",
    title: "Cuenta Pública 2do Trimestre",
    link: "cuentaPub2018/2",
  },
  {
    id: 11,
    year: "2018",
    title: "Cuenta Pública 3er Trimestre",
    link: "cuentaPub2018/3",
  },
  {
    id: 12,
    year: "2018",
    title: "Cuenta Pública 4to Trimestre",
    link: "cuentaPub2018/4",
  },
  {
    id: 13,
    year: "2019",
    title: "Cuenta Pública 1er Trimestre",
    link: "cuentaPub2019/1",
  },
  {
    id: 14,
    year: "2019",
    title: "Cuenta Pública 2do Trimestre",
    link: "cuentaPub2019/2",
  },
  {
    id: 15,
    year: "2019",
    title: "Cuenta Pública 3er Trimestre",
    link: "cuentaPub2019/3",
  },
  {
    id: 16,
    year: "2019",
    title: "Cuenta Pública 4to Trimestre",
    link: "cuentaPub2019/4",
  },
  {
    id: 17,
    year: "2020",
    title: "Cuenta Pública 1er Trimestre",
    link: "cuentaPub2020/1",
  },
  {
    id: 18,
    year: "2020",
    title: "Cuenta Pública 2do Trimestre",
    link: "cuentaPub2020/2",
  },
  {
    id: 19,
    year: "2020",
    title: "Cuenta Pública 3er Trimestre",
    link: "cuentaPub2020/3",
  },
  {
    id: 20,
    year: "2020",
    title: "Cuenta Pública 4to Trimestre",
    link: "cuentaPub2020/4",
  },
  {
    id: 21,
    year: "2021",
    title: "Cuenta Pública 1er Trimestre",
    link: "cuentaPub2021/1",
  },
  {
    id: 22,
    year: "2021",
    title: "Cuenta Pública 2do Trimestre",
    link: "cuentaPub2021/2",
  },
  {
    id: 23,
    year: "2021",
    title: "Cuenta Pública 3er Trimestre",
    link: "cuentaPub2021/3",
  },
  {
    id: 24,
    year: "2021",
    title: "Cuenta Pública 4to Trimestre",
    link: "cuentaPub2021/4",
  },
  {
    id: 25,
    year: "2022",
    title: "Cuenta Pública 1er Trimestre",
    link: "cuentaPub2022/1",
  },
  {
    id: 26,
    year: "2022",
    title: "Cuenta Pública 2do Trimestre",
    link: "cuentaPub2022/2",
  },
  {
    id: 27,
    year: "2022",
    title: "Cuenta Pública 3er Trimestre",
    link: "cuentaPub2022/3",
  },
  {
    id: 28,
    year: "2022",
    title: "Cuenta Pública 4to Trimestre",
    link: "cuentaPub2022/4",
  },
  {
    id: 29,
    year: "2023",
    title: "Cuenta Pública 1er Trimestre",
    link: "cuentaPub2023/1",
  },
  {
    id: 30,
    year: "2023",
    title: "Cuenta Pública 2do Trimestre",
    link: "cuentaPub2023/2",
  },
  {
    id: 30,
    year: "2023",
    title: "Cuenta Pública 3er Trimestre",
    link: "cuentaPub2023/3",
  },
  {
    id: 31,
    year: "2023",
    title: "Cuenta Pública 4to Trimestre",
    link: "cuentaPub2023/4",
  },
  {
    id: 32,
    year: "2024",
    title: "Cuenta Pública 1er Trimestre",
    link: "cuentaPub2024/1",
  },
  {
    id: 33,
    year: "2024",
    title: "Cuenta Pública 2do Trimestre",
    link: "cuentaPub2024/2",
  },
  {
    id: 34,
    year: "2024",
    title: "Cuenta Pública 3er Trimestre",
    link: "cuentaPub2024/3",
  },
];
