import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { MaterialReactTable } from "material-react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import TitlePages from "../../../layout/TitlePages";
import Breadcrumbs from "../../../layout/Breadcrumbs";
import SinExpandir from "../../../layout/HelperDataTable/SinExpandir";
import ListBadge from "../../../layout/ListBadge";
import { FilterYear, useSimpleTableConfig } from "../../../constants";
import { dataInfContable } from "../../../data/dataTransparencia";
import { dataCuentaPublicaNew } from "../../../data/2024/dataCuentaPublica";

const baseUrl = "https://itetlax.org.mx/assets/pdf/transparencia/infContable";

const PdfLink = ({ url }) => {
  const fullUrl = `${baseUrl}/${url}`;
  return (
    <a href={fullUrl} target="_blank" rel="noreferrer">
      <FontAwesomeIcon icon={faFilePdf} className="btn btn-danger" />
    </a>
  );
};

const renderCell = ({ cell }) => <PdfLink url={cell.getValue() + ".pdf"} />;

const InformacionContable = () => {
  useEffect(() => {
    document.title = `Información Contable`;
  }, []);

  const columns = useMemo(() => {
    return [
      {
        accessorKey: "id",
        header: "ID",
        footer: "ID",
        enableResizing: false,
        size: 50,
      },
      {
        accessorKey: "year",
        header: "AÑO",
        footer: "AÑO",
        enableResizing: false,
        Filter: FilterYear,
        size: 50,
      },
      {
        accessorFn: (row) => `${row.title} ${row.year}`,
        id: "title",
        header: "NOMBRE",
        footer: "NOMBRE",
      },
      {
        accessorKey: "link",
        header: "",
        footer: "",
        Cell: renderCell,
      },
    ];
  }, []);

  const table = useSimpleTableConfig(dataCuentaPublicaNew, columns);

  return (
    <>
      <Breadcrumbs
        path={[
          { label: "Transparencia", url: "/Transparencia" },
          { label: "Información Contable" },
        ]}
      />
      <TitlePages title="Transparencia" subTitle="Información Contable" />
      <ListBadge
        ifNumbered=""
        listsBadgeItem={dataInfContable}
        clasName="w-75 mx-auto"
      />
      <br />
      <br />
      <TitlePages title="" subTitle="Cuenta Pública" />
      <SinExpandir />
      <MaterialReactTable table={table} />
    </>
  );
};

export default InformacionContable;

PdfLink.propTypes = {
  url: PropTypes.string.isRequired,
};
