export const dataMonitoreoPG = [
  {
    id: 1,
    yearBoletin: "2024",
    monthBoletin: "ENE",
    nameBoletin: "Primer Reporte Semanal del 02 de enero al 08 de enero",
  },
  {
    id: 2,
    yearBoletin: "2024",
    monthBoletin: "ENE",
    nameBoletin: "Segundo Reporte Semanal del 09 de enero a 15 de enero",
  },
  {
    id: 3,
    yearBoletin: "2024",
    monthBoletin: "ENE",
    nameBoletin: "Primer Informe Quincenal del 2 de enero al 16 de enero",
  },
  {
    id: 4,
    yearBoletin: "2024",
    monthBoletin: "ENE",
    nameBoletin: "Tercer Reporte Semanal del 16 de enero a 21 de enero",
  },
  {
    id: 5,
    yearBoletin: "2024",
    monthBoletin: "ENE",
    nameBoletin: "Segundo Informe Quincenal del 17 de enero al 21 de enero",
  },
  {
    id: 6,
    yearBoletin: "2024",
    monthBoletin: "ENE",
    nameBoletin: "Primer Informe Mensual del 22 de enero al 5 de febrero",
  },
  {
    id: 7,
    yearBoletin: "2024",
    monthBoletin: "ENE",
    nameBoletin:
      "Informe Quincenal Intercampaña del 22 de enero al 5 de febrero",
  },
  {
    id: 8,
    yearBoletin: "2024",
    monthBoletin: "FEB",
    nameBoletin: "Informe Quincenal Intercampaña del 6 al 20 de febrero",
  },
  {
    id: 9,
    yearBoletin: "2024",
    monthBoletin: "FEB",
    nameBoletin: "Informe Quincenal del 21 de febrero al 6 de marzo",
  },
  {
    id: 10,
    yearBoletin: "2024",
    monthBoletin: "MAR",
    nameBoletin: "Informe Quincenal del 07 al 21 de marzo",
  },
  {
    id: 11,
    yearBoletin: "2024",
    monthBoletin: "MAR",
    nameBoletin: "Informe Quincenal del 22 de marzo al 5 de abril",
  },
  {
    id: 12,
    yearBoletin: "2024",
    monthBoletin: "ABR",
    nameBoletin: "Informe Quincenal del 06 de abril al 20 de abril",
  },
  {
    id: 13,
    yearBoletin: "2024",
    monthBoletin: "ABR",
    nameBoletin: "Informe Quincenal del 21 al 29 de abril",
  },
  {
    id: 14,
    yearBoletin: "2024",
    monthBoletin: "MAY",
    nameBoletin: "Cuarto Reporte Semanal del 30 de abril al 06 de mayo",
  },
  {
    id: 15,
    yearBoletin: "2024",
    monthBoletin: "MAY",
    nameBoletin: "Quinto Reporte Semanal del 07 al 13 de mayo",
  },
  {
    id: 16,
    yearBoletin: "2024",
    monthBoletin: "MAY",
    nameBoletin: "Sexto Reporte Semanal del 14 al 20 de mayo",
  },
  {
    id: 17,
    yearBoletin: "2024",
    monthBoletin: "MAY",
    nameBoletin: "Séptimo Reporte Semanal del 21 al 27 de mayo",
  },
  {
    id: 18,
    yearBoletin: "2024",
    monthBoletin: "MAY",
    nameBoletin: "Octavo Reporte Semanal del 28 al 29 de mayo",
  },
  {
    id: 19,
    yearBoletin: "2024",
    monthBoletin: "MAY",
    nameBoletin: "Tercer Informe Quincenal del 30 de abril al 14 de mayo",
  },
  {
    id: 20,
    yearBoletin: "2024",
    monthBoletin: "MAY",
    nameBoletin: "Cuarto Informe Quincenal del 15 al 29 de mayo",
  },
  {
    id: 21,
    yearBoletin: "2024",
    monthBoletin: "MAY",
    nameBoletin: "Segundo Informe Mensual del 30 de abril al 29 de mayo",
  },
  {
    id: 22,
    yearBoletin: "2024",
    monthBoletin: "JUN",
    nameBoletin: "INFORME FINAL DE GÉNERO 2024",
  },
];


export const dataMonitoreoRyT = [
  {
    id: 1,
    yearBoletin: "2024",
    monthBoletin: "ENE",
    nameBoletin: "Primer Reporte Semanal del 02 al 08 de enero",
  },
  {
    id: 2,
    yearBoletin: "2024",
    monthBoletin: "ENE",
    nameBoletin: "Segundo Reporte Semanal del 09 al 15 de enero",
  },
  {
    id: 3,
    yearBoletin: "2024",
    monthBoletin: "ENE",
    nameBoletin: "Primer Informe Quincenal del 2 al 16 de enero",
  },
  {
    id: 4,
    yearBoletin: "2024",
    monthBoletin: "ENE",
    nameBoletin: "Tercer Reporte Semanal del 16 al 21 de enero",
  },

  {
    id: 5,
    yearBoletin: "2024",
    monthBoletin: "ENE",
    nameBoletin: "Segundo Informe Quincenal del 17 al 21 de enero",
  },
  {
    id: 6,
    yearBoletin: "2024",
    monthBoletin: "ENE",
    nameBoletin: "Primer Informe Mensual del 2 al 21 de enero",
  },
  {
    id: 7,
    yearBoletin: "2024",
    monthBoletin: "ENE",
    nameBoletin:
      "Informe Quincenal Intercampaña del 22 de enero al 5 de febrero",
  },
  {
    id: 8,
    yearBoletin: "2024",
    monthBoletin: "FEB",
    nameBoletin: "Informe Quincenal Intercampaña del 6 al 20 de febrero",
  },
  {
    id: 9,
    yearBoletin: "2024",
    monthBoletin: "FEB",
    nameBoletin: "Informe Quincenal del 21 de febrero al 6 de marzo",
  },
  {
    id: 10,
    yearBoletin: "2024",
    monthBoletin: "MAR",
    nameBoletin: "Informe Quincenal del 07 al 21 de marzo",
  },
  {
    id: 11,
    yearBoletin: "2024",
    monthBoletin: "MAR",
    nameBoletin: "Informe Quincenal del 22 de marzo al 5 de abril",
  },
  {
    id: 12,
    yearBoletin: "2024",
    monthBoletin: "ABR",
    nameBoletin: "Informe Quincenal del 06 de abril al 20 de abril",
  },
  {
    id: 13,
    yearBoletin: "2024",
    monthBoletin: "ABR",
    nameBoletin: "Informe Quincenal del 21 al 29 de abril",
  },
  {
    id: 14,
    yearBoletin: "2024",
    monthBoletin: "MAY",
    nameBoletin: "Informe Quincenal del 30 de abril al 06 de mayo",
  },
  {
    id: 15,
    yearBoletin: "2024",
    monthBoletin: "MAY",
    nameBoletin: "Tercer Informe Quincenal del 30 de abril al 14 de mayo",
  },
  {
    id: 16,
    yearBoletin: "2024",
    monthBoletin: "MAY",
    nameBoletin: "Cuarto Informe Quincenal del 15 de mayo al 29 de mayo",
  },
  {
    id: 17,
    yearBoletin: "2024",
    monthBoletin: "MAY",
    nameBoletin: "Reporte Semanal del 07 al 13 de mayo",
  },
  {
    id: 18,
    yearBoletin: "2024",
    monthBoletin: "MAY",
    nameBoletin: "Reporte semanal del 14 al 20 de mayo",
  },
  {
    id: 19,
    yearBoletin: "2024",
    monthBoletin: "MAY",
    nameBoletin: "Reporte semanal del 21 al 27 de mayo",
  },
  {
    id: 20,
    yearBoletin: "2024",
    monthBoletin: "MAY",
    nameBoletin: "Reporte semanal del 28 al 29 de mayo",
  },
  {
    id: 21,
    yearBoletin: "2024",
    monthBoletin: "MAY",
    nameBoletin: "Segundo Informe Mensual del 30 de abril al 29 de mayo",
  },
  {
    id: 22,
    yearBoletin: "2024",
    monthBoletin: "JUN",
    nameBoletin: "Informe Final Radio y televisión",
  },
];

export const dataMonitoreoMIyD = [
  {
    id: 1,
    yearBoletin: "2024",
    monthBoletin: "ENE",
    nameBoletin: "Quinto Informe Quincenal",
  },
  {
    id: 2,
    yearBoletin: "2024",
    monthBoletin: "ENE",
    nameBoletin: "Sexto Informe Quincenal",
  },
  {
    id: 3,
    yearBoletin: "2024",
    monthBoletin: "ENE",
    nameBoletin: "Séptimo Informe Quincenal",
  },

  {
    id: 4,
    yearBoletin: "2024",
    monthBoletin: "FEB",
    nameBoletin: "Octavo Informe Quincenal",
  },

  {
    id: 5,
    yearBoletin: "2024",
    monthBoletin: "MAR",
    nameBoletin: "Noveno Informe Quincenal",
  },
  {
    id: 6,
    yearBoletin: "2024",
    monthBoletin: "MAR",
    nameBoletin: "Decimo Informe Quincenal",
  },
  {
    id: 7,
    yearBoletin: "2024",
    monthBoletin: "ABR",
    nameBoletin: "Decimo Primer Informe Quincenal",
  },
  {
    id: 8,
    yearBoletin: "2024",
    monthBoletin: "ABR",
    nameBoletin: "Duodécimo Informe Quincenal",
  },
  {
    id: 9,
    yearBoletin: "2024",
    monthBoletin: "ABR",
    nameBoletin: "Decimotercer Informe Quincenal",
  },
  {
    id: 10,
    yearBoletin: "2024",
    monthBoletin: "MAY",
    nameBoletin: "Decimocuarto Informe Quincenal",
  },
  {
    id: 11,
    yearBoletin: "2024",
    monthBoletin: "JUN",
    nameBoletin: "Decimoquinto Informe Quincenal",
  },
  {
    id: 12,
    yearBoletin: "2024",
    monthBoletin: "JUN",
    nameBoletin:
      "INFORME FINAL DEL 02 DE NOVIEMBRE DE 2023 AL 13 DE JUNIO 2024  ",
  },
];
