export const dataAvisosPrivacidad = [
  {
    id: 1,
    titulo: "Constitución y Registro de Partidos Políticos Locales",
    simplificado: "1.pdf",
    integral: "2.pdf",
    corto: "3.pdf",
  },
  {
    id: 2,
    titulo: "Red de Candidatas PELO 2020-2021",
    simplificado: "4.pdf",
    integral: "5.pdf",
  },
  {
    id: 3,
    titulo: "Acreditación de medios de comunicación",
    simplificado: "6.pdf",
    integral: "7.pdf",
    corto: "8.pdf",
  },
  {
    id: 4,
    titulo: "Cuestionario sobre condiciones de salud",
    simplificado: "9.pdf",
    integral: "10.pdf",
    corto: "11.pdf",
  },
  {
    id: 5,
    titulo: "Entradas y Salidas",
    simplificado: "12.pdf",
    integral: "13.pdf",
    corto: "14.pdf",
  },
  {
    id: 6,
    titulo: "Eventos y Concursos",
    simplificado: "15.pdf",
    integral: "16.pdf",
    corto: "17.pdf",
  },
  {
    id: 7,
    titulo: "Filtro Sanitario",
    simplificado: "18.pdf",
    integral: "19.pdf",
    corto: "20.pdf",
  },
  {
    id: 8,
    titulo: "Archivo Fotográfico",
    simplificado: "21.pdf",
    integral: "22.pdf",
    corto: "23.pdf",
  },
  {
    id: 9,
    titulo: "Integración de Consejos Distritales y Municipales PELO 2020-2021",
    simplificado: "24.pdf",
    integral: "25.pdf",
    corto: "26.pdf",
  },
  {
    id: 10,
    titulo: "Procedimientos y solicitudes",
    simplificado: "27.pdf",
    integral: "28.pdf",
    corto: "29.pdf",
  },
  {
    id: 11,
    titulo: "Recursos Humanos",
    simplificado: "30.pdf",
    integral: "31.pdf",
    corto: "32.pdf",
  },
  {
    id: 12,
    titulo:
      "Registro de Candidaturas para el Proceso Electoral Local Ordinario 2020-2021",
    simplificado: "33.pdf",
    integral: "34.pdf",
    corto: "35.pdf",
  },
  {
    id: 13,
    titulo: "Servicios de capacitación",
    simplificado: "36.pdf",
    integral: "37.pdf",
    corto: "38.pdf",
  },
  {
    id: 14,
    titulo: "Trámites y procedimientos administrativos",
    simplificado: "39.pdf",
    integral: "40.pdf",
    corto: "41.pdf",
  },
  {
    id: 15,
    titulo: "Red de Mujeres Electas",
    simplificado: "42.pdf",
    integral: "43.pdf",
    corto: "44.pdf",
  },
  {
    id: 16,
    titulo: "Registro de Candidaturas PELE 2021",
    simplificado: "45.pdf",
    integral: "46.pdf",
    corto: "47.pdf",
  },
  {
    id: 17,
    titulo:
      "Consulta previa, libre e informada a las Comunidades que eligen autoridades por el sistema de usos y costumbres y a las indígenas en el Estado de Tlaxcala",
    simplificado: "48.pdf",
    integral: "49.pdf",
    corto: "50.pdf",
  },
  {
    id: 18,
    titulo: "Directorio de Autoridades Electas en Tlaxcala",
    simplificado: "51.pdf",
    integral: "52.pdf",
    corto: "53.pdf",
  },
  {
    id: 19,
    titulo: "Procedimientos de Candidaturas Independientes PELO 2023-2024",
    simplificado: "54.pdf",
    integral: "55.pdf",
    corto: "56.pdf",
  },
  {
    id: 20,
    titulo:
      "Sistema de Candidatas y Candidatos Conóceles para el PELO 2023-2024",
    simplificado: "57.pdf",
    integral: "58.pdf",
  },
  {
    id: 21,
    titulo:
      "Atención a mujeres posibles víctimas de Violencia Política contra las Mujeres en Razón de Género",
    simplificado: "59.pdf",
    integral: "60.pdf",
    corto: "61.pdf",
  },
  {
    id: 22,
    titulo: "Observadores Electorales PELE 2024",
    simplificado: "62.pdf",
    integral: "63.pdf",
    corto: "64.pdf",
  },
  {
    id: 23,
    titulo: "Sistema de Candidatas y Candidatos Conóceles PELE 2024",
    simplificado: "65.pdf",
    integral: "66.pdf",
    corto: "67.pdf",
  },
  {
    id: 24,
    titulo: "Registro de Candidaturas PELE 2024",
    simplificado: "68.pdf",
    integral: "69.pdf",
    corto: "70.pdf",
  },
];
