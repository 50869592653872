export const dataRegistros = [
  // {
  //   id: 1,
  //   nombre: "MAria Eugenia Flores Hernandez",
  //   sexo: "Hombre",
  //   cargo: "Gerente",
  //   ambito: "publico",
  //   sentencia: "Alguna",
  //   expediente: "1/01/20",
  //   autoridad: "Jefe",
  //   conducta: "Mala",
  //   fecha: "01/01/2023",
  //   sancion: "Mucha",
  //   permanenciaReg: "5 años",
  //   reincidencia: "Si",
  // },
];
export const dataInfografias = [
  {
    id: 1,
    link: "https://itetlax.org.mx/assets/img/reps/i1.jpg",
    alt: "",
  },
  {
    id: 2,
    link: "https://itetlax.org.mx/assets/img/reps/i2.png",
    alt: "",
  },
  {
    id: 3,
    link: "https://itetlax.org.mx/assets/img/reps/i3.png",
    alt: "",
  },
  {
    id: 4,
    link: "https://itetlax.org.mx/assets/img/reps/i4.png",
    alt: "",
  },
  {
    id: 5,
    link: "https://itetlax.org.mx/assets/img/reps/i5.jpg",
    alt: "",
  },
  {
    id: 6,
    link: "https://itetlax.org.mx/assets/img/reps/i6.jpg",
    alt: "",
  },
  {
    id: 7,
    link: "https://itetlax.org.mx/assets/img/reps/i7.jpg",
    alt: "",
  },
];
