export const dataMCMIyDPELE2024 = [
  {
    id: 1,
    yearBoletin: "2024",
    monthBoletin: "SEP",
    nameBoletin: "Primer Informe del 24 al 30 de septiembre",
  },
  {
    id: 2,
    yearBoletin: "2024",
    monthBoletin: "OCT",
    nameBoletin: "Segundo informe del 01 al 15 de octubre",
  },
  {
    id: 3,
    yearBoletin: "2024",
    monthBoletin: "OCT",
    nameBoletin: "Tercer informe del 16 al 31 de octubre",
  },
  {
    id: 4,
    yearBoletin: "2024",
    monthBoletin: "NOV",
    nameBoletin: "Cuarto informe del 10 al 15 de noviembre",
  },
  {
    id: 5,
    yearBoletin: "2024",
    monthBoletin: "NOV",
    nameBoletin: "Quinto informe del 16 al 23 de noviembre",
  },
  {
    id: 6,
    yearBoletin: "2024",
    monthBoletin: "NOV",
    nameBoletin: "Informe final del 24 de septiembre al 24 de noviembre",
  },
];

export const dataRyTPELE2024 = [
  {
    id: 1,
    yearBoletin: "2024",
    monthBoletin: "OCT",
    nameBoletin: "Primer Reporte Semanal del 15 al 22 de octubre",
  },
  {
    id: 2,
    yearBoletin: "2024",
    monthBoletin: "OCT",
    nameBoletin: "Primer Informe Quincenal del 15 al 24 de octubre",
  },
  {
    id: 3,
    yearBoletin: "2024",
    monthBoletin: "OCT",
    nameBoletin: "Segundo Reporte Semanal del 23 al 24 de octubre",
  },
  {
    id: 4,
    yearBoletin: "2024",
    monthBoletin: "NOV",
    nameBoletin: "Tercer Reporte Semanal del 06 al 13 de noviembre",
  },
  {
    id: 5,
    yearBoletin: "2024",
    monthBoletin: "NOV",
    nameBoletin: "Segundo Informe Quincenal del 06 al 20 de noviembre",
  },
  {
    id: 6,
    yearBoletin: "2024",
    monthBoletin: "NOV",
    nameBoletin: "Cuarto Reporte Semanal del 14 al 20 de noviembre",
  },
  {
    id: 7,
    yearBoletin: "2024",
    monthBoletin: "NOV",
    nameBoletin: "Informe final del 15 de octubre al 20 de noviembre de 2024",
  },
];
