import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { MaterialReactTable } from "material-react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import TitlePages from "../../../layout/TitlePages";
import Breadcrumbs from "../../../layout/Breadcrumbs";
import SinExpandir from "../../../layout/HelperDataTable/SinExpandir";
import { FilterComponent, useSimpleTableConfig } from "../../../constants";
import { dataAcuerdos2002, dataAA2002 } from "../../../data/dataAcuerdos";

const baseUrl = "https://itetlax.org.mx/assets/pdf/acuerdos/ITE/2002";

const PdfLink = ({ url }) => {
  const fullUrl = `${baseUrl}${url}`;
  return (
    <a href={fullUrl} target="_blank" rel="noreferrer">
      <FontAwesomeIcon icon={faFilePdf} className="btn btn-danger" />
    </a>
  );
};

const renderCell = ({ cell }) => {
  const url = String(cell.getValue());
  return <PdfLink url={url} />;
};

const Acuerdos2002 = () => {
  useEffect(() => {
    document.title = `Acuerdos ITE 2002`;
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "monthDoc",
        header: "MES",
        footer: "MES",
        Filter: FilterComponent,
        size: 50,
      },
      {
        accessorFn: (row) => `${row.typeDoc} ${row.nameDoc}`,
        id: "titulo",
        header: "TÍTULO",
        footer: "TÍTULO",
      },
      {
        accessorFn: (row) => `${row.id}.pdf`,
        accessorKey: "id",
        header: "",
        Cell: renderCell,
      },
    ],
    []
  );

  const columnsAA = useMemo(
    () => [
      {
        accessorKey: "monthDoc",
        header: "MES",
        Filter: FilterComponent,
        size: 50,
      },
      {
        accessorFn: (row) => `${row.typeDoc} ${row.nameDoc}`,
        id: "titulo",
        header: "TÍTULO",
      },
      {
        accessorKey: "link",
        header: "",
        Cell: renderCell,
        size: 50,
      },
    ],
    []
  );

  const table = useSimpleTableConfig(dataAcuerdos2002, columns);
  const tableAA = useSimpleTableConfig(dataAA2002, columnsAA);

  return (
    <>
      <Breadcrumbs
        path={[
          { label: "Acuerdos Anteriores", url: "/AcuerdosAnteriores" },
          { label: `Acuerdos ITE 2002` },
        ]}
      />
      <TitlePages title="Acuerdos Anteriores" subTitle="Acuerdos ITE 2002" />
      <SinExpandir />
      <MaterialReactTable table={table} />
      <TitlePages title="" subTitle="Acuerdos ITE Aprobados 2002" />
      <MaterialReactTable table={tableAA} />
    </>
  );
};

export default Acuerdos2002;

PdfLink.propTypes = {
  url: PropTypes.string.isRequired,
};
