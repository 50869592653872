import PropTypes from "prop-types";
import { useMaterialReactTable } from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { MenuItem, TextField } from "@mui/material";

export const FilterComponent = ({ header }) => (
  <TextField
    onChange={(e) => header.column.setFilterValue(e.target.value || undefined)}
    select
    value={header.column.getFilterValue() ?? ""}
    margin="none"
    placeholder="Filter"
    variant="standard"
    fullWidth
  >
    <MenuItem value={null}>Todos</MenuItem>
    <MenuItem value="ENE">Enero</MenuItem>
    <MenuItem value="FEB">Febrero</MenuItem>
    <MenuItem value="MAR">Marzo</MenuItem>
    <MenuItem value="ABR">Abril</MenuItem>
    <MenuItem value="MAY">Mayo</MenuItem>
    <MenuItem value="JUN">Junio</MenuItem>
    <MenuItem value="JUL">Julio</MenuItem>
    <MenuItem value="AGO">Agosto</MenuItem>
    <MenuItem value="SEP">Septiembre</MenuItem>
    <MenuItem value="OCT">Octubre</MenuItem>
    <MenuItem value="NOV">Noviembre</MenuItem>
    <MenuItem value="DIC">Diciembre</MenuItem>
  </TextField>
);

FilterComponent.propTypes = {
  header: PropTypes.shape({
    column: PropTypes.shape({
      setFilterValue: PropTypes.func.isRequired,
      getFilterValue: PropTypes.func.isRequired,
    }).isRequired,
  }).isRequired,
};

export const FilterYear = ({ header }) => (
  <TextField
    onChange={(e) => header.column.setFilterValue(e.target.value || undefined)}
    select
    value={header.column.getFilterValue() ?? ""}
    margin="none"
    placeholder="Filtrar"
    variant="standard"
    fullWidth
  >
    <MenuItem value={null}>Todos</MenuItem>
    <MenuItem value="2014">2014</MenuItem>
    <MenuItem value="2015">2015</MenuItem>
    <MenuItem value="2016">2016</MenuItem>
    <MenuItem value="2017">2017</MenuItem>
    <MenuItem value="2018">2018</MenuItem>
    <MenuItem value="2019">2019</MenuItem>
    <MenuItem value="2020">2020</MenuItem>
    <MenuItem value="2021">2021</MenuItem>
    <MenuItem value="2022">2022</MenuItem>
    <MenuItem value="2023">2023</MenuItem>
    <MenuItem value="2024">2024</MenuItem>
  </TextField>
);

FilterYear.propTypes = {
  header: PropTypes.shape({
    column: PropTypes.shape({
      setFilterValue: PropTypes.func.isRequired,
      getFilterValue: PropTypes.func.isRequired,
    }).isRequired,
  }).isRequired,
};

export const useSimpleTableConfig = (data, columns, options = {}) => {
  return useMaterialReactTable({
    columns,
    data,
    enableSorting: false,
    enableHiding: false,
    enableColumnActions: false,
    enableDensityToggle: false,
    initialState: { density: "compact" },
    enablePagination: false,
    muiPaginationProps: {
      rowsPerPageOptions: [10, 25, 50, 100, 200, 300, 400],
    },
    localization: {
      ...MRT_Localization_ES,
      pagination: { rowsPerPage: "Filas por página" },
    },
  });
};

export const useCompleteTableConfig = (
  data,
  columns,
  renderDetailPanel,
  options = {}
) => {
  return useMaterialReactTable({
    columns,
    data,
    enableExpanding: true,
    enableExpandAll: true,
    enableSorting: false,
    enableHiding: false,
    enableColumnActions: false,
    enableDensityToggle: false,
    initialState: { density: "compact" },
    muiExpandButtonProps: ({ row }) => ({
      sx: {
        display: row.original.subRows === "" ? "none" : "flex",
      },
    }),
    renderDetailPanel,
    muiPaginationProps: {
      rowsPerPageOptions: [10, 25, 50, 100, 200, 300, 400],
    },
    localization: {
      ...MRT_Localization_ES,
      pagination: {
        rowsPerPage: "Filas por página",
      },
    },
  });
};

export const useSubRowsTableConfig = (
  data,
  columns,
  renderDetailPanel,
  options = {}
) => {
  return useMaterialReactTable({
    columns,
    data,
    enableExpanding: true,
    enableExpandAll: true,
    enableSorting: false,
    enableHiding: false,
    enableColumnActions: false,
    enableDensityToggle: false,
    initialState: { density: "compact" },
    muiExpandButtonProps: ({ row }) => ({
      sx: {
        display: row.original.subRows === "" ? "none" : "flex",
      },
    }),
    renderDetailPanel,
    muiPaginationProps: {
      rowsPerPageOptions: [10, 25, 50, 100, 200, 300, 400],
    },
    localization: {
      ...MRT_Localization_ES,
      pagination: {
        rowsPerPage: "Filas por página",
      },
    },
    ...(options.getSubRows && { getSubRows: options.getSubRows }),
    ...options,
  });
};
