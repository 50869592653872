export const dataGaleria = {
  2019: [
    {
      id: 1,
      titleItem: "Mayo 2019",
      children: [
        {
          id: 1,
          nameActivity: "Convención Distrital 8° Parlamento Juvenil",
          dateActivity: "Mayo de 2019",
          subChilden: [
            {
              id: 1,
              alt: "Foto2019_1",
              link: "1.jpg",
            },
            {
              id: 2,
              alt: "Foto2019_2",
              link: "2.jpg",
            },
            {
              id: 3,
              alt: "Foto2019_3",
              link: "3.jpg",
            },
            {
              id: 4,
              alt: "Foto2019_4",
              link: "4.jpeg",
            },
          ],
        },
        {
          id: 2,
          nameActivity: "Convención Distrital 8° Parlamento Juvenil",
          dateActivity: "Mayo de 2019",
          subChilden: [
            {
              id: 1,
              alt: "Foto2019_5",
              link: "5.jpg",
            },
            {
              id: 2,
              alt: "Foto2019_6",
              link: "6.jpg",
            },
            {
              id: 3,
              alt: "Foto2019_7",
              link: "7.jpg",
            },
            {
              id: 4,
              alt: "Foto2019_8",
              link: "8.jpg",
            },
            {
              id: 5,
              alt: "Foto2019_9",
              link: "9.jpg",
            },
            {
              id: 6,
              alt: "Foto2019_10",
              link: "10.jpg",
            },
            {
              id: 7,
              alt: "Foto2019_11",
              link: "11.jpg",
            },
          ],
        },
        {
          id: 3,
          nameActivity: "Curso de Identidad Institucional",
          dateActivity: "Mayo de 2019",
          subChilden: [
            {
              id: 1,
              alt: "Foto2019_12",
              link: "12.jpg",
            },
            {
              id: 2,
              alt: "Foto2019_13",
              link: "13.jpg",
            },
            {
              id: 3,
              alt: "Foto2019_14",
              link: "14.jpg",
            },
            {
              id: 4,
              alt: "Foto2019_15",
              link: "15.jpeg",
            },
          ],
        },
        {
          id: 4,
          nameActivity:
            "Curso Taller: Clasificación y desclasificación de la información",
          dateActivity: "Mayo de 2019",
          subChilden: [
            {
              id: 1,
              alt: "Foto2019_16",
              link: "16.jpg",
            },
            {
              id: 2,
              alt: "Foto2019_17",
              link: "17.jpg",
            },
            {
              id: 3,
              alt: "Foto2019_18",
              link: "18.jpg",
            },
            {
              id: 4,
              alt: "Foto2019_19",
              link: "19.jpg",
            },
            {
              id: 5,
              alt: "Foto2019_20",
              link: "20.jpg",
            },
            {
              id: 6,
              alt: "Foto2019_21",
              link: "21.jpg",
            },
            {
              id: 7,
              alt: "Foto2019_22",
              link: "22.jpg",
            },
            {
              id: 8,
              alt: "Foto2019_23",
              link: "23.jpg",
            },
          ],
        },
        {
          id: 5,
          nameActivity:
            "Los derechos políticos electorales y la participación ciudadana. Conversatorio",
          dateActivity: "20 de Mayo de 2019",
          subChilden: [
            {
              id: 1,
              alt: "Foto2019_24",
              link: "24.jpg",
            },
            {
              id: 2,
              alt: "Foto2019_25",
              link: "25.jpg",
            },
            {
              id: 3,
              alt: "Foto2019_26",
              link: "26.jpg",
            },
            {
              id: 4,
              alt: "Foto2019_27",
              link: "27.jpeg",
            },
            {
              id: 5,
              alt: "Foto2019_28",
              link: "28.jpeg",
            },
            {
              id: 6,
              alt: "Foto2019_29",
              link: "29.jpeg",
            },
            {
              id: 7,
              alt: "Foto2019_30",
              link: "30.jpeg",
            },
          ],
        },
        {
          id: 6,
          nameActivity:
            "Los derechos políticos electorales y la participación ciudadana. Conversatorio",
          dateActivity: "21 de Mayo de 2019",
          subChilden: [
            {
              id: 1,
              alt: "Foto2019_31",
              link: "31.jpg",
            },
            {
              id: 2,
              alt: "Foto2019_32",
              link: "32.jpg",
            },
            {
              id: 3,
              alt: "Foto2019_33",
              link: "33.jpg",
            },
            {
              id: 4,
              alt: "Foto2019_34",
              link: "34.jpeg",
            },
          ],
        },
        {
          id: 7,
          nameActivity: "Reunión en Guadalupe Ixcotla",
          dateActivity: "Mayo de 2019",
          subChilden: [
            {
              id: 1,
              alt: "Foto2019_35",
              link: "35.jpg",
            },
            {
              id: 2,
              alt: "Foto2019_36",
              link: "36.jpg",
            },
            {
              id: 3,
              alt: "Foto2019_37",
              link: "37.jpg",
            },
            {
              id: 4,
              alt: "Foto2019_38",
              link: "38.jpg",
            },
            {
              id: 5,
              alt: "Foto2019_39",
              link: "39.jpg",
            },
            {
              id: 6,
              alt: "Foto2019_40",
              link: "40.jpg",
            },
            {
              id: 7,
              alt: "Foto2019_41",
              link: "41.jpg",
            },
            {
              id: 8,
              alt: "Foto2019_42",
              link: "42.jpg",
            },
            {
              id: 9,
              alt: "Foto2019_43",
              link: "43.jpg",
            },
            {
              id: 10,
              alt: "Foto2019_44",
              link: "44.jpg",
            },
          ],
        },
        {
          id: 8,
          nameActivity:
            "Sesión del Observatorio de Participación Política de las Mujeres en Tlaxcala",
          dateActivity: "Mayo de 2019",
          subChilden: [
            {
              id: 1,
              alt: "Foto2019_45",
              link: "45.jpg",
            },
            {
              id: 2,
              alt: "Foto2019_46",
              link: "46.jpg",
            },
            {
              id: 3,
              alt: "Foto2019_47",
              link: "47.jpg",
            },
            {
              id: 4,
              alt: "Foto2019_48",
              link: "48.jpg",
            },
            {
              id: 5,
              alt: "Foto2019_49",
              link: "49.jpg",
            },
          ],
        },
      ],
    },
    {
      id: 2,
      titleItem: "Junio 2019",
      children: [
        {
          id: 1,
          nameActivity:
            "Curso: Periodismo con perspectiva de género: ética, igualdad y diversidad",
          dateActivity: "Junio de 2019",
          subChilden: [
            {
              id: 1,
              alt: "Foto2019_50",
              link: "50.jpg",
            },
            {
              id: 2,
              alt: "Foto2019_51",
              link: "51.jpg",
            },
            {
              id: 3,
              alt: "Foto2019_52",
              link: "52.jpg",
            },
            {
              id: 4,
              alt: "Foto2019_53",
              link: "53.jpg",
            },
            {
              id: 5,
              alt: "Foto2019_54",
              link: "54.jpg",
            },
            {
              id: 6,
              alt: "Foto2019_55",
              link: "55.jpg",
            },
            {
              id: 7,
              alt: "Foto2019_56",
              link: "56.jpg",
            },
            {
              id: 8,
              alt: "Foto2019_57",
              link: "57.jpg",
            },
            {
              id: 9,
              alt: "Foto2019_58",
              link: "58.jpg",
            },
          ],
        },
        {
          id: 2,
          nameActivity: "Diálogos Juveniles: Cultura Democrática",
          dateActivity: "Junio de 2019",
          subChilden: [
            {
              id: 1,
              alt: "Foto2019_59",
              link: "59.jpg",
            },
            {
              id: 2,
              alt: "Foto2019_60",
              link: "60.jpg",
            },
            {
              id: 3,
              alt: "Foto2019_61",
              link: "61.jpeg",
            },
            {
              id: 4,
              alt: "Foto2019_62",
              link: "62.jpeg",
            },
          ],
        },
        {
          id: 3,
          nameActivity: "Elecciones por Usos y Costumbres - Santa Ana Portales",
          dateActivity: "Junio de 2019",
          subChilden: [
            {
              id: 1,
              alt: "Foto2019_63",
              link: "63.jpg",
            },
            {
              id: 2,
              alt: "Foto2019_64",
              link: "64.jpg",
            },
            {
              id: 3,
              alt: "Foto2019_65",
              link: "65.jpeg",
            },
            {
              id: 4,
              alt: "Foto2019_66",
              link: "66.jpeg",
            },
          ],
        },
        {
          id: 4,
          nameActivity: "Ensayos: Parlamento Juvenil 2019",
          dateActivity: "Junio de 2019",
          subChilden: [
            {
              id: 1,
              alt: "Foto2019_67",
              link: "67.jpg",
            },
            {
              id: 2,
              alt: "Foto2019_68",
              link: "68.jpg",
            },
            {
              id: 3,
              alt: "Foto2019_69",
              link: "69.jpg",
            },
            {
              id: 4,
              alt: "Foto2019_70",
              link: "70.jpg",
            },
            {
              id: 5,
              alt: "Foto2019_71",
              link: "71.jpg",
            },
            {
              id: 6,
              alt: "Foto2019_72",
              link: "72.jpg",
            },
          ],
        },
        {
          id: 5,
          nameActivity: "Rueda de prensa:  Resultados de la Consulta Infantil",
          dateActivity: "Junio de 2019",
          subChilden: [
            {
              id: 1,
              alt: "Foto2019_73",
              link: "73.jpg",
            },
            {
              id: 2,
              alt: "Foto2019_74",
              link: "74.jpg",
            },
            {
              id: 3,
              alt: "Foto2019_75",
              link: "75.jpg",
            },
            {
              id: 4,
              alt: "Foto2019_76",
              link: "76.jpeg",
            },
          ],
        },
      ],
    },
  ],

  2018: [
    {
      id: 1,
      titleItem: "Abril 2018",
      children: [
        {
          id: 1,
          nameActivity: "Convención Estatal 10° Parlamento Infantil 2017",
          dateActivity: "10 de Abril de 2018",
          subChilden: [
            {
              id: 1,
              alt: "Foto2018_1",
              link: "1.jpg",
            },
            {
              id: 2,
              alt: "Foto2018_2",
              link: "2.jpg",
            },
            {
              id: 3,
              alt: "Foto2018_3",
              link: "3.jpg",
            },
            {
              id: 4,
              alt: "Foto2018_4",
              link: "4.jpg",
            },
            {
              id: 5,
              alt: "Foto2018_5",
              link: "5.jpg",
            },
            {
              id: 6,
              alt: "Foto2018_6",
              link: "6.jpg",
            },
            {
              id: 7,
              alt: "Foto2018_7",
              link: "7.jpg",
            },
            {
              id: 8,
              alt: "Foto2018_8",
              link: "8.jpg",
            },
            {
              id: 9,
              alt: "Foto2018_9",
              link: "9.jpg",
            },
            {
              id: 10,
              alt: "Foto2018_10",
              link: "10.jpg",
            },
            {
              id: 11,
              alt: "Foto2018_11",
              link: "11.jpg",
            },
            {
              id: 12,
              alt: "Foto2018_12",
              link: "12.jpg",
            },
            {
              id: 13,
              alt: "Foto2018_13",
              link: "13.jpg",
            },
            {
              id: 14,
              alt: "Foto2018_14",
              link: "14.jpg",
            },
            {
              id: 15,
              alt: "Foto2018_15",
              link: "15.jpg",
            },
            {
              id: 16,
              alt: "Foto2018_16",
              link: "16.jpg",
            },
            {
              id: 17,
              alt: "Foto2018_17",
              link: "17.jpg",
            },
            {
              id: 18,
              alt: "Foto2018_18",
              link: "18.jpg",
            },
            {
              id: 19,
              alt: "Foto2018_19",
              link: "19.jpg",
            },
            {
              id: 20,
              alt: "Foto2018_20",
              link: "20.jpg",
            },
            {
              id: 21,
              alt: "Foto2018_21",
              link: "21.jpg",
            },
            {
              id: 22,
              alt: "Foto2018_22",
              link: "22.jpg",
            },
            {
              id: 23,
              alt: "Foto2018_23",
              link: "23.jpg",
            },
            {
              id: 24,
              alt: "Foto2018_24",
              link: "24.jpg",
            },
            {
              id: 25,
              alt: "Foto2018_25",
              link: "25.jpg",
            },
            {
              id: 26,
              alt: "Foto2018_26",
              link: "26.jpg",
            },
            {
              id: 27,
              alt: "Foto2018_27",
              link: "27.jpg",
            },
            {
              id: 28,
              alt: "Foto2018_28",
              link: "28.jpg",
            },
            {
              id: 29,
              alt: "Foto2018_29",
              link: "29.jpg",
            },
            {
              id: 30,
              alt: "Foto2018_30",
              link: "30.jpg",
            },
            {
              id: 31,
              alt: "Foto2018_31",
              link: "31.jpg",
            },
            {
              id: 32,
              alt: "Foto2018_32",
              link: "32.jpg",
            },
            {
              id: 33,
              alt: "Foto2018_33",
              link: "33.jpg",
            },
            {
              id: 34,
              alt: "Foto2018_34",
              link: "34.jpg",
            },
            {
              id: 35,
              alt: "Foto2018_35",
              link: "35.jpg",
            },
            {
              id: 36,
              alt: "Foto2018_36",
              link: "36.jpg",
            },
            {
              id: 37,
              alt: "Foto2018_37",
              link: "37.jpg",
            },
            {
              id: 38,
              alt: "Foto2018_38",
              link: "38.jpg",
            },
            {
              id: 39,
              alt: "Foto2018_39",
              link: "39.jpg",
            },
            {
              id: 40,
              alt: "Foto2018_40",
              link: "40.jpg",
            },
            {
              id: 41,
              alt: "Foto2018_41",
              link: "41.jpg",
            },
            {
              id: 42,
              alt: "Foto2018_42",
              link: "42.jpg",
            },
            {
              id: 43,
              alt: "Foto2018_43",
              link: "43.jpg",
            },
            {
              id: 44,
              alt: "Foto2018_44",
              link: "44.jpg",
            },
            {
              id: 45,
              alt: "Foto2018_45",
              link: "45.jpg",
            },
          ],
        },
      ],
    },
  ],

  2017: [
    {
      id: 1,
      titleItem: "Marzo 2017",
      children: [
        {
          id: 1,
          nameActivity: "Mesas institucionales de Análisis Electoral",
          dateActivity: "24 de Junio de 2017",
          subChilden: [
            {
              id: 1,
              alt: "Foto2017_1",
              link: "1.jpg",
            },
            {
              id: 2,
              alt: "Foto2017_2",
              link: "2.jpg",
            },
            {
              id: 3,
              alt: "Foto2017_3",
              link: "3.jpg",
            },
            {
              id: 4,
              alt: "Foto2017_4",
              link: "4.jpg",
            },
            {
              id: 5,
              alt: "Foto2017_5",
              link: "5.jpg",
            },
            {
              id: 6,
              alt: "Foto2017_6",
              link: "6.jpg",
            },
            {
              id: 7,
              alt: "Foto2017_7",
              link: "7.jpg",
            },
            {
              id: 8,
              alt: "Foto2017_8",
              link: "8.jpg",
            },
            {
              id: 9,
              alt: "Foto2017_9",
              link: "9.jpg",
            },
            {
              id: 10,
              alt: "Foto2017_10",
              link: "10.jpg",
            },
            {
              id: 11,
              alt: "Foto2017_11",
              link: "11.jpg",
            },
            {
              id: 12,
              alt: "Foto2017_12",
              link: "12.jpg",
            },
            {
              id: 13,
              alt: "Foto2017_13",
              link: "13.jpg",
            },
            {
              id: 14,
              alt: "Foto2017_14",
              link: "14.jpg",
            },
            {
              id: 15,
              alt: "Foto2017_15",
              link: "15.jpg",
            },
            {
              id: 16,
              alt: "Foto2017_16",
              link: "16.jpg",
            },
            {
              id: 17,
              alt: "Foto2017_17",
              link: "17.jpg",
            },
            {
              id: 18,
              alt: "Foto2017_18",
              link: "18.jpg",
            },
            {
              id: 19,
              alt: "Foto2017_19",
              link: "19.jpg",
            },
            {
              id: 20,
              alt: "Foto2017_20",
              link: "20.jpg",
            },
            {
              id: 21,
              alt: "Foto2017_21",
              link: "21.jpg",
            },
            {
              id: 22,
              alt: "Foto2017_22",
              link: "22.jpg",
            },
          ],
        },
        {
          id: 2,
          nameActivity: "Teatro: Ellas en escena AC 'Monólogo'",
          dateActivity: "31 de Marzo de 2017",
          subChilden: [
            {
              id: 1,
              alt: "Foto2017_23",
              link: "23.jpg",
            },
            {
              id: 2,
              alt: "Foto2017_24",
              link: "24.jpg",
            },
            {
              id: 3,
              alt: "Foto2017_25",
              link: "25.jpg",
            },
            {
              id: 4,
              alt: "Foto2017_26",
              link: "26.jpg",
            },
            {
              id: 5,
              alt: "Foto2017_27",
              link: "27.jpg",
            },
            {
              id: 6,
              alt: "Foto2017_28",
              link: "28.jpg",
            },
            {
              id: 7,
              alt: "Foto2017_29",
              link: "29.jpg",
            },
            {
              id: 8,
              alt: "Foto2017_30",
              link: "30.jpg",
            },
            {
              id: 9,
              alt: "Foto2017_31",
              link: "31.jpg",
            },
            {
              id: 10,
              alt: "Foto2017_32",
              link: "32.jpg",
            },
            {
              id: 11,
              alt: "Foto2017_33",
              link: "33.jpg",
            },
            {
              id: 12,
              alt: "Foto2017_34",
              link: "34.jpg",
            },
            {
              id: 13,
              alt: "Foto2017_35",
              link: "35.jpg",
            },
            {
              id: 14,
              alt: "Foto2017_36",
              link: "36.jpg",
            },
            {
              id: 15,
              alt: "Foto2017_37",
              link: "37.jpg",
            },
            {
              id: 16,
              alt: "Foto2017_38",
              link: "38.jpg",
            },
            {
              id: 17,
              alt: "Foto2017_39",
              link: "39.jpg",
            },
            {
              id: 18,
              alt: "Foto2017_40",
              link: "40.jpg",
            },
            {
              id: 19,
              alt: "Foto2017_41",
              link: "41.jpg",
            },
            {
              id: 20,
              alt: "Foto2017_42",
              link: "42.jpg",
            },
            {
              id: 21,
              alt: "Foto2017_43",
              link: "43.jpg",
            },
          ],
        },
      ],
    },
    {
      id: 2,
      titleItem: "Abril 2017",
      children: [
        {
          id: 1,
          nameActivity: "9° Parlamento Infantil 2017",
          dateActivity: "04 de Abril de 2017",
          subChilden: [
            {
              id: 1,
              alt: "Foto2017_43",
              link: "43.jpg",
            },
            {
              id: 2,
              alt: "Foto2017_44",
              link: "44.jpg",
            },
            {
              id: 3,
              alt: "Foto2017_45",
              link: "45.jpg",
            },
            {
              id: 4,
              alt: "Foto2017_46",
              link: "46.jpg",
            },
            {
              id: 5,
              alt: "Foto2017_47",
              link: "47.jpg",
            },
            {
              id: 6,
              alt: "Foto2017_48",
              link: "48.jpg",
            },
            {
              id: 7,
              alt: "Foto2017_49",
              link: "49.jpg",
            },
            {
              id: 8,
              alt: "Foto2017_50",
              link: "50.jpg",
            },
            {
              id: 9,
              alt: "Foto2017_51",
              link: "51.jpg",
            },
            {
              id: 10,
              alt: "Foto2017_52",
              link: "52.jpg",
            },
            {
              id: 11,
              alt: "Foto2017_53",
              link: "53.jpg",
            },
            {
              id: 12,
              alt: "Foto2017_54",
              link: "54.jpg",
            },
            {
              id: 13,
              alt: "Foto2017_55",
              link: "55.jpg",
            },
            {
              id: 14,
              alt: "Foto2017_56",
              link: "56.jpg",
            },
            {
              id: 15,
              alt: "Foto2017_57",
              link: "57.jpg",
            },
            {
              id: 16,
              alt: "Foto2017_58",
              link: "58.jpg",
            },
            {
              id: 17,
              alt: "Foto2017_59",
              link: "59.jpg",
            },
            {
              id: 18,
              alt: "Foto2017_60",
              link: "60.jpg",
            },
            {
              id: 19,
              alt: "Foto2017_61",
              link: "61.jpg",
            },
            {
              id: 20,
              alt: "Foto2017_62",
              link: "62.jpg",
            },
            {
              id: 21,
              alt: "Foto2017_63",
              link: "63.jpg",
            },
            {
              id: 22,
              alt: "Foto2017_64",
              link: "64.jpg",
            },
            {
              id: 23,
              alt: "Foto2017_65",
              link: "65.jpg",
            },
            {
              id: 24,
              alt: "Foto2017_66",
              link: "66.jpg",
            },
            {
              id: 25,
              alt: "Foto2017_67",
              link: "67.jpg",
            },
          ],
        },
        {
          id: 2,
          nameActivity: "Diálogo Democrátivo #2",
          dateActivity: "24 de Abril de 2017",
          subChilden: [
            {
              id: 1,
              alt: "Foto2017_68",
              link: "68.jpg",
            },
            {
              id: 2,
              alt: "Foto2017_69",
              link: "69.jpg",
            },
            {
              id: 3,
              alt: "Foto2017_70",
              link: "70.jpg",
            },
            {
              id: 4,
              alt: "Foto2017_71",
              link: "71.jpg",
            },
            {
              id: 5,
              alt: "Foto2017_72",
              link: "72.jpg",
            },
            {
              id: 6,
              alt: "Foto2017_73",
              link: "73.jpg",
            },
            {
              id: 7,
              alt: "Foto2017_74",
              link: "74.jpg",
            },
            {
              id: 8,
              alt: "Foto2017_75",
              link: "75.jpg",
            },
            {
              id: 9,
              alt: "Foto2017_76",
              link: "76.jpg",
            },
            {
              id: 10,
              alt: "Foto2017_77",
              link: "77.jpg",
            },
            {
              id: 11,
              alt: "Foto2017_78",
              link: "78.jpg",
            },
            {
              id: 12,
              alt: "Foto2017_79",
              link: "79.jpg",
            },
            {
              id: 13,
              alt: "Foto2017_80",
              link: "80.jpg",
            },
          ],
        },
      ],
    },
    {
      id: 3,
      titleItem: "Junio 2017",
      children: [
        {
          id: 1,
          nameActivity: "Jornada Electoral 2017",
          dateActivity: "04 de Junio de 2017",
          subChilden: [
            {
              id: 1,
              alt: "Foto2017_81",
              link: "81.jpg",
            },
            {
              id: 2,
              alt: "Foto2017_82",
              link: "82.jpg",
            },
            {
              id: 3,
              alt: "Foto2017_83",
              link: "83.jpg",
            },
            {
              id: 4,
              alt: "Foto2017_84",
              link: "84.jpg",
            },
            {
              id: 5,
              alt: "Foto2017_85",
              link: "85.jpg",
            },
            {
              id: 6,
              alt: "Foto2017_86",
              link: "86.jpg",
            },
            {
              id: 7,
              alt: "Foto2017_87",
              link: "87.jpg",
            },
            {
              id: 8,
              alt: "Foto2017_88",
              link: "88.jpg",
            },
            {
              id: 9,
              alt: "Foto2017_89",
              link: "89.jpg",
            },
            {
              id: 10,
              alt: "Foto2017_90",
              link: "90.jpg",
            },
            {
              id: 11,
              alt: "Foto2017_91",
              link: "91.jpg",
            },
            {
              id: 12,
              alt: "Foto2017_92",
              link: "92.jpg",
            },
            {
              id: 13,
              alt: "Foto2017_93",
              link: "93.jpg",
            },
            {
              id: 14,
              alt: "Foto2017_94",
              link: "94.jpg",
            },
            {
              id: 15,
              alt: "Foto2017_95",
              link: "95.jpg",
            },
            {
              id: 16,
              alt: "Foto2017_96",
              link: "96.jpg",
            },
            {
              id: 17,
              alt: "Foto2017_97",
              link: "97.jpg",
            },
            {
              id: 18,
              alt: "Foto2017_98",
              link: "98.jpg",
            },
          ],
        },
      ],
    },
    {
      id: 4,
      titleItem: "Julio 2017",
      children: [
        {
          id: 1,
          nameActivity: "6° Parlamento Juvenil 2017",
          dateActivity: "21 de Julio de 2017",
          subChilden: [
            {
              id: 1,
              alt: "Foto2017_99",
              link: "99.jpg",
            },
            {
              id: 2,
              alt: "Foto2017_100",
              link: "100.jpg",
            },
            {
              id: 3,
              alt: "Foto2017_101",
              link: "101.jpg",
            },
            {
              id: 4,
              alt: "Foto2017_102",
              link: "102.jpg",
            },
            {
              id: 5,
              alt: "Foto2017_103",
              link: "103.jpg",
            },
            {
              id: 6,
              alt: "Foto2017_104",
              link: "104.jpg",
            },
            {
              id: 7,
              alt: "Foto2017_105",
              link: "105.jpg",
            },
            {
              id: 8,
              alt: "Foto2017_106",
              link: "106.jpg",
            },
            {
              id: 9,
              alt: "Foto2017_107",
              link: "107.jpg",
            },
            {
              id: 10,
              alt: "Foto2017_108",
              link: "108.jpg",
            },
            {
              id: 11,
              alt: "Foto2017_109",
              link: "109.jpg",
            },
            {
              id: 12,
              alt: "Foto2017_110",
              link: "110.jpg",
            },
            {
              id: 13,
              alt: "Foto2017_111",
              link: "111.jpg",
            },
            {
              id: 14,
              alt: "Foto2017_112",
              link: "112.jpg",
            },
          ],
        },
      ],
    },
    {
      id: 5,
      titleItem: "Agosto 2017",
      children: [
        {
          id: 1,
          nameActivity: "Cómputos Electorales 2017",
          dateActivity: "12 de agosto de 2017",
          subChilden: [
            {
              id: 1,
              alt: "Foto2017_113",
              link: "113.jpg",
            },
            {
              id: 2,
              alt: "Foto2017_114",
              link: "114.jpg",
            },
            {
              id: 3,
              alt: "Foto2017_115",
              link: "115.jpg",
            },
            {
              id: 4,
              alt: "Foto2017_116",
              link: "116.jpg",
            },
            {
              id: 5,
              alt: "Foto2017_117",
              link: "117.jpg",
            },
            {
              id: 6,
              alt: "Foto2017_118",
              link: "118.jpg",
            },
            {
              id: 7,
              alt: "Foto2017_119",
              link: "119.jpg",
            },
            {
              id: 8,
              alt: "Foto2017_120",
              link: "120.jpg",
            },
            {
              id: 9,
              alt: "Foto2017_121",
              link: "121.jpg",
            },
            {
              id: 10,
              alt: "Foto2017_122",
              link: "122.jpg",
            },
            {
              id: 11,
              alt: "Foto2017_123",
              link: "123.jpg",
            },
          ],
        },
      ],
    },
  ],

  2016: [
    {
      id: 1,
      titleItem: "Junio 2016",
      children: [
        {
          id: 1,
          nameActivity:
            "Análisis de Resultados de la Jornada Electoral 2015 - 2016",
          dateActivity: "24 de Junio de 2016",
          subChilden: [
            {
              id: 1,
              alt: "Foto2016_1",
              link: "1.jpg",
            },
            {
              id: 2,
              alt: "Foto2016_2",
              link: "2.jpg",
            },
            {
              id: 3,
              alt: "Foto2016_3",
              link: "3.jpg",
            },
            {
              id: 4,
              alt: "Foto2016_4",
              link: "4.jpg",
            },
            {
              id: 5,
              alt: "Foto2016_5",
              link: "5.jpg",
            },
            {
              id: 6,
              alt: "Foto2016_6",
              link: "6.jpg",
            },
            {
              id: 7,
              alt: "Foto2016_7",
              link: "7.jpg",
            },
            {
              id: 8,
              alt: "Foto2016_8",
              link: "8.jpg",
            },
            {
              id: 9,
              alt: "Foto2016_9",
              link: "9.jpg",
            },
          ],
        },
      ],
    },
    {
      id: 2,
      titleItem: "Julio 2016",
      children: [
        {
          id: 1,
          nameActivity:
            "Entrega de constancias al personal del ITE por su participación en el Proceso Electoral 2015 - 2016",
          dateActivity: "05 de Julio de 2016",
          subChilden: [
            {
              id: 1,
              alt: "Foto2016_10",
              link: "10.jpg",
            },
            {
              id: 2,
              alt: "Foto2016_11",
              link: "11.jpg",
            },
            {
              id: 3,
              alt: "Foto2016_12",
              link: "12.jpg",
            },
            {
              id: 4,
              alt: "Foto2016_13",
              link: "13.jpg",
            },
            {
              id: 5,
              alt: "Foto2016_14",
              link: "14.jpg",
            },
            {
              id: 6,
              alt: "Foto2016_15",
              link: "15.jpg",
            },
            {
              id: 7,
              alt: "Foto2016_16",
              link: "16.jpg",
            },
            {
              id: 8,
              alt: "Foto2016_17",
              link: "17.jpg",
            },
            {
              id: 9,
              alt: "Foto2016_18",
              link: "18.jpg",
            },
            {
              id: 10,
              alt: "Foto2016_19",
              link: "19.jpg",
            },
            {
              id: 11,
              alt: "Foto2016_20",
              link: "20.jpg",
            },
            {
              id: 12,
              alt: "Foto2016_21",
              link: "21.jpg",
            },
            {
              id: 13,
              alt: "Foto2016_22",
              link: "22.jpg",
            },
            {
              id: 14,
              alt: "Foto2016_23",
              link: "23.jpg",
            },
            {
              id: 15,
              alt: "Foto2016_24",
              link: "24.jpg",
            },
            {
              id: 16,
              alt: "Foto2016_25",
              link: "25.jpg",
            },
            {
              id: 17,
              alt: "Foto2016_26",
              link: "26.jpg",
            },
            {
              id: 18,
              alt: "Foto2016_27",
              link: "27.jpg",
            },
            {
              id: 19,
              alt: "Foto2016_28",
              link: "28.jpg",
            },
            {
              id: 20,
              alt: "Foto2016_29",
              link: "29.jpg",
            },
            {
              id: 21,
              alt: "Foto2016_30",
              link: "30.jpg",
            },
            {
              id: 22,
              alt: "Foto2016_31",
              link: "31.jpg",
            },
            {
              id: 23,
              alt: "Foto2016_32",
              link: "32.jpg",
            },
            {
              id: 24,
              alt: "Foto2016_33",
              link: "33.jpg",
            },
            {
              id: 25,
              alt: "Foto2016_34",
              link: "34.jpg",
            },
            {
              id: 26,
              alt: "Foto2016_35",
              link: "35.jpg",
            },
            {
              id: 27,
              alt: "Foto2016_36",
              link: "36.jpg",
            },
            {
              id: 28,
              alt: "Foto2016_37",
              link: "37.jpg",
            },
            {
              id: 29,
              alt: "Foto2016_38",
              link: "38.jpg",
            },
            {
              id: 30,
              alt: "Foto2016_39",
              link: "39.jpg",
            },
            {
              id: 31,
              alt: "Foto2016_40",
              link: "40.jpg",
            },
            {
              id: 32,
              alt: "Foto2016_41",
              link: "41.jpg",
            },
            {
              id: 33,
              alt: "Foto2016_42",
              link: "42.jpg",
            },
            {
              id: 34,
              alt: "Foto2016_43",
              link: "43.jpg",
            },
            {
              id: 35,
              alt: "Foto2016_44",
              link: "44.jpg",
            },
            {
              id: 36,
              alt: "Foto2016_45",
              link: "45.jpg",
            },
            {
              id: 37,
              alt: "Foto2016_46",
              link: "46.jpg",
            },
            {
              id: 38,
              alt: "Foto2016_47",
              link: "47.jpg",
            },
            {
              id: 39,
              alt: "Foto2016_48",
              link: "48.jpg",
            },
            {
              id: 40,
              alt: "Foto2016_49",
              link: "49.jpg",
            },
            {
              id: 41,
              alt: "Foto2016_50",
              link: "50.jpg",
            },
            {
              id: 42,
              alt: "Foto2016_51",
              link: "51.jpg",
            },
            {
              id: 43,
              alt: "Foto2016_52",
              link: "52.jpg",
            },
            {
              id: 44,
              alt: "Foto2016_53",
              link: "53.jpg",
            },
            {
              id: 45,
              alt: "Foto2016_54",
              link: "54.jpg",
            },
          ],
        },
      ],
    },
    {
      id: 3,
      titleItem: "Agosto 2016",
      children: [
        {
          id: 1,
          nameActivity:
            "Entrega ITE constancias a miembros del Parlamento Juvenil 2016",
          dateActivity: "05 de Agosto de 2016",
          subChilden: [
            {
              id: 1,
              alt: "Foto2016_55",
              link: "55.jpg",
            },
            {
              id: 2,
              alt: "Foto2016_56",
              link: "56.jpg",
            },
            {
              id: 3,
              alt: "Foto2016_57",
              link: "57.jpg",
            },
            {
              id: 4,
              alt: "Foto2016_58",
              link: "58.jpg",
            },
            {
              id: 5,
              alt: "Foto2016_59",
              link: "59.jpg",
            },
            {
              id: 6,
              alt: "Foto2016_60",
              link: "60.jpg",
            },
            {
              id: 7,
              alt: "Foto2016_61",
              link: "61.jpg",
            },
            {
              id: 8,
              alt: "Foto2016_62",
              link: "62.jpg",
            },
            {
              id: 9,
              alt: "Foto2016_63",
              link: "63.jpg",
            },
            {
              id: 10,
              alt: "Foto2016_64",
              link: "64.jpg",
            },
            {
              id: 11,
              alt: "Foto2016_65",
              link: "65.jpg",
            },
            {
              id: 12,
              alt: "Foto2016_66",
              link: "66.jpg",
            },
            {
              id: 13,
              alt: "Foto2016_67",
              link: "67.jpg",
            },
            {
              id: 14,
              alt: "Foto2016_68",
              link: "68.jpg",
            },
            {
              id: 15,
              alt: "Foto2016_69",
              link: "69.jpg",
            },
            {
              id: 16,
              alt: "Foto2016_70",
              link: "70.jpg",
            },
            {
              id: 17,
              alt: "Foto2016_71",
              link: "71.jpg",
            },
            {
              id: 18,
              alt: "Foto2016_72",
              link: "72.jpg",
            },
          ],
        },
      ],
    },
    {
      id: 4,
      titleItem: "Septiembre 2016",
      children: [
        {
          id: 1,
          nameActivity:
            "Recuento de 13 de 18 Paquetes de Elección de Ayuntamiento de Tzompantepec",
          dateActivity: "13 de Septimebre de 2016",
          subChilden: [
            {
              id: 1,
              alt: "Foto2016_73",
              link: "73.jpg",
            },
            {
              id: 2,
              alt: "Foto2016_74",
              link: "74.jpg",
            },
            {
              id: 3,
              alt: "Foto2016_75",
              link: "75.jpg",
            },
            {
              id: 4,
              alt: "Foto2016_76",
              link: "76.jpg",
            },
            {
              id: 5,
              alt: "Foto2016_77",
              link: "77.jpg",
            },
            {
              id: 6,
              alt: "Foto2016_78",
              link: "78.jpg",
            },
            {
              id: 7,
              alt: "Foto2016_79",
              link: "79.jpg",
            },
            {
              id: 8,
              alt: "Foto2016_80",
              link: "80.jpg",
            },
          ],
        },
        {
          id: 2,
          nameActivity:
            "Recuento de 86 de 103 paquetes de elección del Distrito 12, con cabecera en Teolocholco",
          dateActivity: "17 de Septimebre de 2016",
          subChilden: [
            {
              id: 1,
              alt: "Foto2016_81",
              link: "81.jpg",
            },
            {
              id: 2,
              alt: "Foto2016_82",
              link: "82.jpg",
            },
            {
              id: 3,
              alt: "Foto2016_83",
              link: "83.jpg",
            },
            {
              id: 4,
              alt: "Foto2016_84",
              link: "84.jpg",
            },
            {
              id: 5,
              alt: "Foto2016_85",
              link: "85.jpg",
            },
            {
              id: 6,
              alt: "Foto2016_86",
              link: "86.jpg",
            },
            {
              id: 7,
              alt: "Foto2016_87",
              link: "87.jpg",
            },
            {
              id: 8,
              alt: "Foto2016_88",
              link: "88.jpg",
            },
            {
              id: 9,
              alt: "Foto2016_89",
              link: "89.jpg",
            },
            {
              id: 10,
              alt: "Foto2016_90",
              link: "90.jpg",
            },
            {
              id: 11,
              alt: "Foto2016_91",
              link: "91.jpg",
            },
            {
              id: 12,
              alt: "Foto2016_92",
              link: "92.jpg",
            },
            {
              id: 13,
              alt: "Foto2016_93",
              link: "93.jpg",
            },
            {
              id: 14,
              alt: "Foto2016_94",
              link: "94.jpg",
            },
            {
              id: 15,
              alt: "Foto2016_95",
              link: "95.jpg",
            },
          ],
        },
      ],
    },
    {
      id: 5,
      titleItem: "Octubre 2016",
      children: [
        {
          id: 1,
          nameActivity: "Conferencia: Sufragio Femennino en México",
          dateActivity: "28 de Octubre de 2016",
          subChilden: [
            {
              id: 1,
              alt: "Foto2016_96",
              link: "96.jpg",
            },
            {
              id: 2,
              alt: "Foto2016_97",
              link: "97.jpg",
            },
            {
              id: 3,
              alt: "Foto2016_98",
              link: "98.jpg",
            },
            {
              id: 4,
              alt: "Foto2016_99",
              link: "99.jpg",
            },
            {
              id: 5,
              alt: "Foto2016_100",
              link: "100.jpg",
            },
            {
              id: 6,
              alt: "Foto2016_101",
              link: "101.jpg",
            },
            {
              id: 7,
              alt: "Foto2016_102",
              link: "102.jpg",
            },
          ],
        },
      ],
    },
  ],
};
