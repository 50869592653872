import React, { useMemo, useEffect } from "react";
import { MaterialReactTable } from "material-react-table";
import { Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import TitlePages from "../../../layout/TitlePages";
import Breadcrumbs from "../../../layout/Breadcrumbs";
import Expandible from "../../../layout/HelperDataTable/Expandible";
import { useSubRowsTableConfig } from "../../../constants";
import { dataArt672024 } from "../../../data/2024/dataArt67";

const baseUrlPDF = "https://itetlax.org.mx/assets/pdf/transparencia/art67/";
const baseUrlExcel = "https://itetlax.org.mx/assets/excel/transparencia/art67/";

const ExcelLink = ({ baseUrl, urls }) => {
  return urls.map((url) => {
    const fullUrl = `${baseUrl}2024/${url}`;
    const displayText = url.substring(0, url.lastIndexOf("."));
    return (
      <tr className="table-secondary" key={fullUrl}>
        <td>{displayText}</td>
        <td>
          <a href={fullUrl} target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faFileExcel} className="btn btn-success" />
          </a>
        </td>
      </tr>
    );
  });
};

const PdfLink = ({ baseUrl, urls }) => {
  return urls.map((url) => {
    const fullUrl = `${baseUrl}2024/${url}`;
    const displayText = url.substring(0, url.lastIndexOf("."));
    return (
      <tr className="table-secondary" key={fullUrl}>
        <td>{displayText}</td>
        <td>
          <a href={fullUrl} target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faFilePdf} className="btn btn-danger" />
          </a>
        </td>
      </tr>
    );
  });
};

const Art672024 = () => {
  useEffect(() => {
    document.title = `Artículo 67 2024`;
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "letra",
        header: "Letra",
        footer: "Letra",
        size: 50,
        enableResizing: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "descriptivo",
        header: "Descriptivo",
        footer: "Descriptivo",
        enableResizing: false,
      },
      {
        accessorKey: "cumplimiento",
        header: "Cumplimiento",
        footer: "Cumplimiento",
        enableResizing: false,
        filterFn: "equals",
        filterSelectOptions: [
          { text: "Aplica", value: "Aplica" },
          { text: "No Aplica", value: "No Aplica" },
        ],
        filterVariant: "select",
        size: 70,
      },
    ],
    []
  );

  const rootData = useMemo(() => dataArt672024.filter((r) => !r.managerId), []);

  const renderTransparencia = ({ row }) => {
    const { managerId, letra, ...rest } = row.original;
    const excels = Object.keys(rest)
      .filter((key) => key.startsWith("excel"))
      .map((key) => rest[key]);
    const pdfs = Object.keys(rest)
      .filter((key) => key.startsWith("pdf"))
      .map((key) => rest[key]);

    if (excels.length > 0 || pdfs.length > 0) {
      return (
        <Box id="Box">
          <div className="table-responsive">
            <table className="table table-hover table-sm table-bordered table align-middle w-25">
              <thead>
                <tr>
                  <td colSpan={2}>
                    <br />
                    <strong>Fracción {letra}</strong>
                    <br />
                    <br />
                  </td>
                </tr>
              </thead>
              <tbody>
                {excels.length > 0 && (
                  <ExcelLink baseUrl={baseUrlExcel} urls={excels} />
                )}
                {pdfs.length > 0 && (
                  <PdfLink baseUrl={baseUrlPDF} urls={pdfs} />
                )}
              </tbody>
            </table>
          </div>
        </Box>
      );
    }
    return null;
  };

  const table = useSubRowsTableConfig(rootData, columns, renderTransparencia, {
    getSubRows: (row) => dataArt672024.filter((r) => r.managerId === row.id),
  });

  return (
    <>
      <Breadcrumbs
        path={[
          { label: "Transparencia", url: "/Transparencia" },
          { label: "Artículo 67 Obligaciones Específicas", url: "/Articulo67" },
          { label: `Artículo 67 2024` },
        ]}
      />
      <TitlePages
        title="Transparencia"
        subTitle="Artículo 67. (2024) Obligaciones Específicas"
      />
      <Expandible />
      <MaterialReactTable table={table} />
    </>
  );
};

export default Art672024;
