import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { MaterialReactTable } from "material-react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileWord, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import TitlePages from "../../layout/TitlePages";
import Breadcrumbs from "../../layout/Breadcrumbs";
import { useSimpleTableConfig } from "../../constants";
import { dataPlaneacion } from "../../data/2024/dataArchivo";

const baseUrlPDF = "https://itetlax.org.mx/assets/pdf/archivistico/";

const PdfLink = ({ url }) => {
  const fullUrl = `${baseUrlPDF}/${url}`;
  return (
    <a href={fullUrl} target="_blank" rel="noreferrer">
      <FontAwesomeIcon icon={faFilePdf} className="btn btn-danger" />
    </a>
  );
};

const WordLink = ({ url }) => {
  const fullUrl = `${baseUrlPDF}/${url}`;
  return (
    <a href={fullUrl} target="_blank" rel="noreferrer">
      <FontAwesomeIcon icon={faFileWord} className="btn btn-primary" />
    </a>
  );
};

const renderCellPDF = ({ cell }) => {
  const pdfFile = cell.getValue();
  return pdfFile ? <PdfLink url={pdfFile + ".pdf"} /> : null;
};

const renderCellWORD = ({ cell }) => {
  const wordFile = cell.getValue();
  return wordFile ? <WordLink url={wordFile + ".docx"} /> : null;
};

const InstrumentosNormativos = () => {
  useEffect(() => {
    document.title = `Planeación Archivística`;
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "NO.",
        footer: "NO.",
        size: 50,
      },
      {
        accessorKey: "nameDoc",
        header: "Normatividad",
        footer: "Normatividad",
      },
      {
        accessorKey: "pdf",
        header: "",
        Cell: renderCellPDF,
      },
      {
        accessorKey: "word",
        header: "",
        Cell: renderCellWORD,
      },
    ],
    []
  );

  const table = useSimpleTableConfig(dataPlaneacion, columns);

  return (
    <>
      <Breadcrumbs path={[{ label: "Planeación Archivística" }]} />
      <TitlePages title="Archivo" subTitle="Planeación Archivística" />
      <MaterialReactTable table={table} />
    </>
  );
};
export default InstrumentosNormativos;

PdfLink.propTypes = {
  url: PropTypes.string.isRequired,
};

WordLink.propTypes = {
  url: PropTypes.string.isRequired,
};
