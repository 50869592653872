export const dataArt94 = {
  2020: [
    {
      fraccion: "I",
      titulo: "Índice de los expedientes reservados",
      cumplimiento: "Aplica",
      excel1: "LTAIPT2018_A94F1.xlsx",
      managerId: null,
    },
  ],
  2021: [
    {
      fraccion: "I",
      titulo: "Índice de los expedientes reservados",
      cumplimiento: "Aplica",
      excel1: "LTAIPT_A94F1.xlsx",
      pdf1: "LTAIPT_A94F1.pdf",
      managerId: null,
    },
  ],
};
