import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { MaterialReactTable } from "material-react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import TitlePages from "../../../layout/TitlePages";
import Breadcrumbs from "../../../layout/Breadcrumbs";
import SinExpandir from "../../../layout/HelperDataTable/SinExpandir";
import { useSimpleTableConfig } from "../../../constants";
import {
  dataAcuerdos2001,
  dataAA12001,
  dataAA22001,
} from "../../../data/dataAcuerdos";

const baseUrl = "https://itetlax.org.mx/assets/pdf/acuerdos/ITE/2001";

const PdfLink = ({ url }) => {
  const fullUrl = `${baseUrl}/${url}`;
  return (
    <a href={fullUrl} target="_blank" rel="noreferrer">
      <FontAwesomeIcon icon={faFilePdf} className="btn btn-danger" />
    </a>
  );
};

const renderCell = ({ cell }) => {
  const url = cell.getValue();
  return <PdfLink url={url} />;
};

const Acuerdos2001 = () => {
  useEffect(() => {
    document.title = `Acuerdos ITE 2001`;
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row.typeDoc} ${row.nameDoc}`,
        id: "titulo",
        header: "TÍTULO",
      },
      {
        accessorKey: "link",
        header: "",
        Cell: renderCell,
      },
    ],
    []
  );

  const columnsAA12001 = useMemo(
    () => [
      {
        accessorKey: "nameMunicipio",
        header: "Título",
        footer: "Título",
      },
      {
        accessorKey: "link",
        header: "",
        Cell: renderCell,
      },
    ],
    []
  );

  const columnsAA22001 = useMemo(
    () => [
      {
        accessorKey: "nameDoc",
        header: "Título",
        footer: "Título",
        size: 50,
      },
      {
        accessorKey: "link",
        header: "",
        Cell: renderCell,
      },
    ],
    []
  );

  const table = useSimpleTableConfig(dataAcuerdos2001, columns);
  const tableAA12001 = useSimpleTableConfig(dataAA12001, columnsAA12001);
  const tableAA22001 = useSimpleTableConfig(dataAA22001, columnsAA22001);

  return (
    <>
      <Breadcrumbs
        path={[
          { label: "Acuerdos Anteriores", url: "/AcuerdosAnteriores" },
          { label: `Acuerdos ITE 2001` },
        ]}
      />
      <TitlePages title="Acuerdos Anteriores" subTitle="" />
      <SinExpandir />
      <TitlePages title="" subTitle="Acuerdos ITE 2001" />
      <MaterialReactTable table={table} />
      <br />
      <br />
      <TitlePages title="" subTitle="Ayuntamientos 10/Nov/2001" />
      <MaterialReactTable table={tableAA12001} />
      <br />
      <br />
      <TitlePages title="" subTitle="Ayuntamientos Dic-2001" />
      <MaterialReactTable table={tableAA22001} />
    </>
  );
};

export default Acuerdos2001;

PdfLink.propTypes = {
  url: PropTypes.string.isRequired,
};
