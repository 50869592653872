export const dataInformesPELO = [
  {
    id: 1,
    year: "2023",
    monthDoc: "Diciembre",
    title: "Primer Informe PELO 2023-2024",
    link: "https://itetlax.org.mx/assets/pdf/informesPELO2324/1.pdf",
  },
  {
    id: 2,
    year: "2024",
    monthDoc: "Enero",
    title: "Segundo Informe PELO 2023-2024",
    link: "https://itetlax.org.mx/assets/pdf/informesPELO2324/2.pdf",
  },
  {
    id: 3,
    year: "2024",
    monthDoc: "Febrero",
    title: "Tercer Informe PELO 2023-2024",
    link: "https://itetlax.org.mx/assets/pdf/informesPELO2324/3.pdf",
  },
  {
    id: 4,
    year: "2024",
    monthDoc: "Marzo",
    title: "Cuarto Informe PELO 2023-2024",
    link: "https://itetlax.org.mx/assets/pdf/informesPELO2324/4.pdf",
  },
  {
    id: 5,
    year: "2024",
    monthDoc: "Abril",
    title: "Quinto Informe PELO 2023-2024",
    link: "https://itetlax.org.mx/assets/pdf/informesPELO2324/5.pdf",
  },
  {
    id: 6,
    year: "2024",
    monthDoc: "Mayo",
    title: "Sexto Informe PELO 2023-2024",
    link: "https://itetlax.org.mx/assets/pdf/informesPELO2324/6.pdf",
  },
  {
    id: 7,
    year: "2024",
    monthDoc: "Junio",
    title: "Septimo Informe PELO 2023-2024",
    link: "https://itetlax.org.mx/assets/pdf/informesPELO2324/7.pdf",
  },
];
