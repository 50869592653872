export const dataITEConecta = [
  {
    id: 1,
    url: "oHAQ5EJHqDg",
    title:
      "ITE Conecta Programa 1, ¿A las y los jóvenes les interesa lo público?",
  },
  {
    id: 2,
    url: "TY3pMrnByAY",
    title: "ITE Conecta Programa 2, Activismo feminista de las jóvenes",
  },
  {
    id: 3,
    url: "Ck2rsNpRVNY",
    title:
      "ITE Conecta Programa 3, La consulta a las comunidades que eligen a sus autoridades por usos y costumbres y a las indígenas del Estado",
  },
  {
    id: 4,
    url: "QshTN9cX39M",
    title:
      "ITE Conecta Programa 4, Arte visual urbano con  Valeria Álvarez Espejel (VAL)",
  },
  {
    id: 5,
    url: "xlOzEPao9GQ",
    title:
      "ITE Conecta Programa 5, Centennials y millennials con Alberto Conde",
  },
  {
    id: 6,
    url: "1qLe04tVVjo",
    title: "ITE Conecta Programa 6, La credencial para votar",
  },
  {
    id: 7,
    url: "8Vz0AG9tQQI",
    title:
      "ITE Conecta Programa 7, Derechos político electorales de las personas con discapacidad",
  },
  {
    id: 8,
    url: "0y4E6_SgxeE",
    title: "ITE Conecta Programa 8, La comunicación oral de las juventudes",
  },
  {
    id: 9,
    url: "OR85GFZvTKA",
    title:
      "ITE Conecta Programa 9, La perspectiva de lo público en las niñas y los niños",
  },
  {
    id: 10,
    url: "O_XNx7WgWPc",
    title:
      "ITE Conecta Programa 10, Constitución de nuevos partidos políticos en Tlaxcala",
  },
  {
    id: 11,
    url: "g80XkWFejcA",
    title:
      "ITE Conecta Programa 11, Las redes sociales como espacio de participación para las y los jóvenes",
  },
  {
    id: 12,
    url: "U73JJCsin40",
    title: "ITE Conecta Programa 12, El derecho al uso de las calles",
  },
  {
    id: 13,
    url: "wk8WBxugcIc",
    title:
      "ITE Conecta Programa 13, Elecciones escolares con el Mtro. Norberto Sánchez Briones",
  },
  {
    id: 14,
    url: "8ojbGfeIebM",
    title: "ITE Conecta Programa 14, Tribus Urbanas, comunidad friki",
  },
  {
    id: 15,
    url: "WvkDOXUDUR8",
    title:
      "ITE Conecta Programa 15, Inclusión, Lengua de Señas Mexicana con Adriana Tecpa Sartillo",
  },
];
