//APP
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";

//Layout
import Header from "./layout/Header";
import NavbarMenu from "./layout/NavbarMenu";
import Footer from "./layout/Footer";
import ScrollToTop from "./layout/ScrollToTop/ScrollToTop";
import AppRoutes from "./routes";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Header></Header>
      <NavbarMenu></NavbarMenu>
      <div className="mt-3 App__container">
        <AppRoutes />
      </div>
      <Footer></Footer>
    </Router>
  );
}

export default App;
