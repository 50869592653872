export const dataNormatividad = [
  {
    id: 1,
    nameButton: "lGenerales",
    titleButton: "Leyes Generales",
    children: [
      {
        id: 1,
        nameDoc: "Ley General de Archivo",
        post: "DOF 15/06/2018",
        lastUpdate: "DOF 19/01/2023",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/lGenerales/1.pdf",
        pdfMobile:
          "https://itetlax.org.mx/assets/pdf/normatividad/lGenerales/1.1.pdf",
        word: "https://itetlax.org.mx/assets/word/normatividad/lGenerales/1.doc",
      },
      {
        id: 2,
        nameDoc: "Ley General de Instituciones y Procedimientos Electorales",
        post: "DOF 23/05/2014",
        lastUpdate: "DOF 20/12/2022",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/lGenerales/2.pdf",
        pdfMobile:
          "https://itetlax.org.mx/assets/pdf/normatividad/lGenerales/2.1.pdf",
        word: "https://itetlax.org.mx/assets/word/normatividad/lGenerales/2.doc",
      },
      {
        id: 3,
        nameDoc: "Ley General de Partidos Políticos",
        post: "DOF 23/05/2014",
        lastUpdate: "Notificación 11/10/2022 Sentencia SCJN",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/lGenerales/3.pdf",
        pdfMobile:
          "https://itetlax.org.mx/assets/pdf/normatividad/lGenerales/3.1.pdf",
        word: "https://itetlax.org.mx/assets/word/normatividad/lGenerales/3.doc",
      },
      {
        id: 4,
        nameDoc:
          "Ley General de Sistema de Medios de Impugnación en Materia Electoral",
        post: "DOF 22/11/1996",
        lastUpdate: "DOF 20/12/2022",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/lGenerales/4.pdf",
        pdfMobile:
          "https://itetlax.org.mx/assets/pdf/normatividad/lGenerales/4.1.pdf",
        word: "https://itetlax.org.mx/assets/word/normatividad/lGenerales/4.doc",
      },
      {
        id: 5,
        nameDoc: "Ley General de Transparencia y Acceso a la Información",
        post: "DOF 04/05/2015",
        lastUpdate: "DOF 20/05/2021",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/lGenerales/5.pdf",
        pdfMobile:
          "https://itetlax.org.mx/assets/pdf/normatividad/lGenerales/5.1.pdf",
        word: "https://itetlax.org.mx/assets/word/normatividad/lGenerales/5.pdf",
      },
      {
        id: 6,
        nameDoc: "Ley General en Materia de Delitos Electorales",
        post: "DOF 23/05/2014",
        lastUpdate: "DOF 20/05/2021",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/lGenerales/6.pdf",
        pdfMobile:
          "https://itetlax.org.mx/assets/pdf/normatividad/lGenerales/6.1.pdf",
        word: "https://itetlax.org.mx/assets/word/normatividad/lGenerales/6.pdf",
      },
      {
        id: 7,
        nameDoc: "Ley General del Sistema Nacional Anticorrupción",
        post: "DOF 23/05/2014",
        lastUpdate: "DOF 20/05/2021",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/lGenerales/7.pdf",
        pdfMobile:
          "https://itetlax.org.mx/assets/pdf/normatividad/lGenerales/7.1.pdf",
        word: "https://itetlax.org.mx/assets/word/normatividad/lGenerales/7.pdf",
      },
      {
        id: 8,
        nameDoc:
          "Ley General de Protección de Datos Personales en Posesión de Sujetos Obligados",
        post: "DOF 26/01/2017",
        lastUpdate: "Sin reforma",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/lGenerales/8.pdf",
        pdfMobile:
          "https://itetlax.org.mx/assets/pdf/normatividad/lGenerales/8.1.pdf",
        word: "https://itetlax.org.mx/assets/word/normatividad/lGenerales/8.pdf",
      },
      {
        id: 9,
        nameDoc: "Ley General de Contabilidad Gubernamental",
        post: "DOF 31/12/2018",
        lastUpdate: "DOF 30/01/2018",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/lGenerales/9.pdf",
        pdfMobile:
          "https://itetlax.org.mx/assets/pdf/normatividad/lGenerales/9.1.pdf",
        word: "https://itetlax.org.mx/assets/word/normatividad/lGenerales/9.pdf",
      },
    ],
  },
  {
    id: 2,
    nameButton: "lFederales",
    titleButton: "Leyes Federales",
    children: [
      {
        id: 1,
        nameDoc: "Constitución Política de los Estados Unidos Mexicanos",
        post: "DOF 05/02/1917",
        lastUpdate: "DOF 06/06/2023",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/lFederales/1.pdf",
        // pdfMobile:
        //   "https://itetlax.org.mx/assets/pdf/normatividad/lFederales/1.1.pdf",
        // word: "https://itetlax.org.mx/assets/word/normatividad/lFederales/1.doc",
      },
      {
        id: 2,
        nameDoc: "Ley Federal de Consulta Popular",
        post: "DOF 14/03/2014",
        lastUpdate: "DOF 19/05/2021",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/lFederales/2.pdf",
        // pdfMobile:
        //   "https://itetlax.org.mx/assets/pdf/normatividad/lFederales/2.1.pdf",
        // word: "https://itetlax.org.mx/assets/word/normatividad/lFederales/2.doc",
      },
      {
        id: 3,
        nameDoc: "Ley General de Instituciones y Procedimientos Electorales",
        post: "DOF 23/05/2014",
        lastUpdate: "DOF 02/03/2023 (Notificación 26/06/2023, sentencia SCJN)",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/lFederales/3.pdf",
      },
      {
        id: 4,
        nameDoc: "Ley General de Partidos Políticos",
        post: "DOF 23/05/2014",
        lastUpdate: "DOF 02/03/2023 (Notificación 26/06/2023, sentencia SCJN)",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/lFederales/4.pdf",
      },
      {
        id: 5,
        nameDoc:
          "Ley General del Sistema de Medios de Impugnación en Materia Electoral",
        post: "DOF 22/11/1996",
        lastUpdate: "DOF 20/12/2022)",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/lFederales/5.pdf",
      },
      {
        id: 6,
        nameDoc: "Ley General en Materia de Delitos Electorales",
        post: "DOF 23/05/2014",
        lastUpdate: "DOF 20/05/2021)",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/lFederales/6.pdf",
      },
      {
        id: 7,
        nameDoc: "Ley General de Archivos",
        post: "DOF 15/06/2018",
        lastUpdate: "DOF 19/01/2023)",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/lFederales/7.pdf",
      },
      {
        id: 8,
        nameDoc:
          "Ley General de Transparencia y Acceso a la Información Pública",
        post: "DOF 04/05/2015",
        lastUpdate: "DOF 20/05/2021)",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/lFederales/8.pdf",
      },
      {
        id: 9,
        nameDoc: "Ley General del Sistema Nacional Anticorrupción",
        post: "DOF 18/06/2016",
        lastUpdate: "DOF 20/05/2021)",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/lFederales/9.pdf",
      },
      {
        id: 10,
        nameDoc:
          "Ley General de Protección de Datos Personales en Posesión de Sujetos Obligados",
        post: "DOF 26/01/2017",
        lastUpdate: "Sin reforma",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/lFederales/10.pdf",
      },
      {
        id: 11,
        nameDoc: "Ley General de Contabilidad Gubernamental",
        post: "DOF 31/12/2008",
        lastUpdate: "DOF 30/01/2018)",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/lFederales/11.pdf",
      },
      {
        id: 12,
        nameDoc: "Ley General de la Fiscalía General de la República",
        post: "DOF 20/05/2021",
        lastUpdate: "Sin reforma",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/lFederales/12.pdf",
      },
      {
        id: 13,
        nameDoc:
          "Ley General de Acceso de las Mujeres a una Vida Libre de Violencia",
        post: "DOF 01/02/2007",
        lastUpdate: "DOF 08/05/2023)",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/lFederales/13.pdf",
      },

      {
        id: 14,
        nameDoc: "Ley Federal del Trabajo",
        post: "DOF 01/04/1970",
        lastUpdate: "DOF 27/12/2022)",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/lFederales/14.pdf",
      },
      {
        id: 15,
        nameDoc:
          "Ley Federal de Transparencia y Acceso a la Información Pública",
        post: "DOF 09/05/2016",
        lastUpdate: "DOF 20/05/2021)",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/lFederales/15.pdf",
      },
      {
        id: 16,
        nameDoc: "Ley General de Responsabilidades Administrativas",
        post: "DOF 18/06/2016",
        lastUpdate: "DOF 27/12/2022)",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/lFederales/16.pdf",
      },
    ],
  },
  {
    id: 3,
    nameButton: "lEstatales",
    titleButton: "Leyes Estatales",
    children: [
      {
        id: 1,
        nameDoc:
          "Constitución Política del Estado Libre y Soberano de Tlaxcala",
        post: "",
        lastUpdate: "25/05/2022",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/lEstatales/1.pdf",
        pdfMobile: "",
        word: "https://itetlax.org.mx/assets/word/normatividad/lEstatales/1.doc",
      },
      {
        id: 2,
        nameDoc:
          "Ley de Transparencia y Acceso a la Información Pública del Estado de Tlaxcala",
        post: "",
        lastUpdate: "13/09/2021",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/lEstatales/2.pdf",
        pdfMobile: "",
        word: "https://itetlax.org.mx/assets/word/normatividad/lEstatales/2.docx",
      },
      {
        id: 3,
        nameDoc: "Ley de Archivos del Estado de Tlaxcala",
        post: "",
        lastUpdate: "10/05/2021",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/lEstatales/3.pdf",
        pdfMobile: "",
        word: "https://itetlax.org.mx/assets/word/normatividad/lEstatales/3.docx",
      },
      {
        id: 4,
        nameDoc: "Ley de Consulta Ciudadana para el Estado de Tlaxcala",
        post: "",
        lastUpdate: "12/05/2009",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/lEstatales/4.pdf",
        pdfMobile: "",
        word: "https://itetlax.org.mx/assets/word/normatividad/lEstatales/4.docx",
      },
      {
        id: 5,
        nameDoc:
          "Ley de Instituciones y Procedimientos Electorales para el Estado de Tlaxcala",
        post: "",
        lastUpdate: "25/04/2022",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/lEstatales/5.pdf",
        pdfMobile: "",
        word: "https://itetlax.org.mx/assets/word/normatividad/lEstatales/5.docx",
      },
      {
        id: 6,
        nameDoc:
          "Ley de Medios de Impugnación en Materia Electoral para el Estado de Tlaxcala",
        post: "",
        lastUpdate: "25/04/2022",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/lEstatales/6.pdf",
        pdfMobile: "",
        word: "https://itetlax.org.mx/assets/word/normatividad/lEstatales/6.docx",
      },
      {
        id: 7,
        nameDoc: "Ley de Partidos Políticos para el Estado de Tlaxcala",
        post: "",
        lastUpdate: "27/08/2020",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/lEstatales/7.pdf",
        pdfMobile: "",
        word: "https://itetlax.org.mx/assets/word/normatividad/lEstatales/7.docx",
      },
      {
        id: 8,
        nameDoc:
          "Ley de Protección de Datos Personales en posesion de sujetos obligados para el Estado de Tlaxcala",
        post: "",
        lastUpdate: "18/07/2017",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/lEstatales/8.pdf",
        pdfMobile: "",
        word: "https://itetlax.org.mx/assets/word/normatividad/lEstatales/8.docx",
      },
      {
        id: 9,
        nameDoc:
          "Ley de Responsabilidades de los Servidores Públicos del Estado de Tlaxcala",
        post: "",
        lastUpdate: "12/04/2018",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/lEstatales/9.pdf",
        pdfMobile: "",
        word: "https://itetlax.org.mx/assets/word/normatividad/lEstatales/9.docx",
      },
      {
        id: 10,
        nameDoc: "Ley del sistema anticorrupcion del estado de tlaxcala",
        post: "",
        lastUpdate: "14/10/2019",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/lEstatales/10.pdf",
        pdfMobile: "",
        word: "https://itetlax.org.mx/assets/word/normatividad/lEstatales/10.docx",
      },
      {
        id: 11,
        nameDoc:
          "Ley Laboral de los Servidores Públicos del Estado de Tlaxcala y sus Municipios",
        post: "",
        lastUpdate: "22/12/2021",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/lEstatales/11.pdf",
        pdfMobile: "",
        word: "https://itetlax.org.mx/assets/word/normatividad/lEstatales/11.docx",
      },
      {
        id: 12,
        nameDoc:
          "Ley de Fiscalización Superior para el Estado de Tlaxcala y sus Municipios",
        post: "",
        lastUpdate: "29/09/2020",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/lEstatales/12.pdf",
        pdfMobile: "",
        word: "https://itetlax.org.mx/assets/word/normatividad/lEstatales/12.docx",
      },
      {
        id: 13,
        nameDoc:
          "Ley de Procedimiento Administrativo del Estado de Tlaxcala y sus Municipios",
        post: "",
        lastUpdate: "29/09/2020",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/lEstatales/13.pdf",
        pdfMobile: "",
        word: "https://itetlax.org.mx/assets/word/normatividad/lEstatales/13.docx",
      },
      {
        id: 14,
        nameDoc: "Ley Municipal del Estado de Tlaxcala",
        post: "",
        lastUpdate: "09/03/2022",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/lEstatales/14.pdf",
        pdfMobile: "",
        word: "https://itetlax.org.mx/assets/word/normatividad/lEstatales/14.docx",
      },
      {
        id: 15,
        nameDoc:
          "Ley de Entrega  Recepción para el Estado de Tlaxcala y sus Municipios",
        post: "",
        lastUpdate: "29/09/2020",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/lEstatales/15.pdf",
        pdfMobile: "",
        word: "https://itetlax.org.mx/assets/word/normatividad/lEstatales/15.docx",
      },
    ],
  },
  {
    id: 4,
    nameButton: "reglamentos",
    titleButton: "Reglamentos",
    children: [
      {
        id: 1,
        nameDoc:
          "Reglamento del Instituto Tlaxcalteca de Elecciones en Materia de Transparencia y Acceso a la Información Pública",
        post: "07/03/2018",
        lastUpdate: "12/10/2020",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/reglamentos/1.pdf",
        pdfMobile: "",
        word: "",
      },
      {
        id: 2,
        nameDoc:
          "Reglamento de Quejas y Denuncias del Instituto Tlaxcalteca de Elecciones",
        post: "30/11/2015",
        lastUpdate: "20/01/2021",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/reglamentos/2.pdf",
        pdfMobile: "",
        word: "",
      },
      {
        id: 3,
        nameDoc:
          "Reglamento de Asistencia Técnica, Jurídica y Logística a las Comunidades que realizan elecciones de Presidentes de Comunidad por el Sistema de Usos y Costumbres",
        post: "",
        lastUpdate: "",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/reglamentos/18.pdf",
        pdfMobile: "",
        word: "",
      },
      {
        id: 4,
        nameDoc:
          "Reglamento de la Oficialía Electoral del Instituto Tlaxcalteca de Elecciones",
        post: "30/11/2015",
        lastUpdate: "Sin reforma",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/reglamentos/4.pdf",
        pdfMobile: "",
        word: "",
      },
      {
        id: 5,
        nameDoc: "Reglamento Interior del Instituto Tlaxcalteca de Elecciones",
        post: "30/11/2015",
        lastUpdate: "31/01/2023",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/reglamentos/5.pdf",
        pdfMobile: "",
        word: "",
      },
      {
        id: 6,
        nameDoc:
          "Reglamento de los Consejos Distritales y Municipales Electorales del Instituto Tlaxcalteca de Elecciones",
        post: "27/11/2015",
        lastUpdate: "12/03/2021",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/reglamentos/6.pdf",
        pdfMobile: "",
        word: "",
      },
      {
        id: 7,
        nameDoc:
          "Reglamento para la Clasificación y Desclasificación de la Información del Instituto Tlaxcalteca de Elecciones",
        post: "07/03/2015",
        lastUpdate: "Sin reforma",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/reglamentos/7.pdf",
        pdfMobile: "",
        word: "",
      },
      {
        id: 8,
        nameDoc:
          "Reglamento del Instituto Tlaxcalteca de Elecciones relativo al Procedimiento para la Pérdida de Registro y Cancelación de la Acreditación, así como la Recuperación de Activos de los Partidos Políticos",
        post: "30/09/2015",
        lastUpdate: "Sin reforma",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/reglamentos/8.pdf",
        pdfMobile: "",
        word: "",
      },
      {
        id: 9,
        nameDoc:
          "Reglamento de Comisiones del Instituto Tlaxcalteca de Elecciones",
        post: "03/12/2015",
        lastUpdate: "Sin reforma",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/reglamentos/9.pdf",
        pdfMobile: "",
        word: "",
      },
      {
        id: 10,
        nameDoc: "Reglamento de sesiones del Consejo General",
        post: "23/10/2015",
        lastUpdate: "Sin reforma",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/reglamentos/10.pdf",
        pdfMobile: "",
        word: "",
      },
      {
        id: 11,
        nameDoc:
          "Reglamento del Comité de Igualdad Laboral y No Discriminación del Instituto Tlaxcalteca de Elecciones",
        post: "27/11/2019",
        lastUpdate: "Sin reforma",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/reglamentos/11.pdf",
        pdfMobile: "",
        word: "",
      },
      {
        id: 12,
        nameDoc:
          "Reglamento de la Junta General Ejecutiva del Instituto Tlaxcalteca de Elecciones",
        post: "27/11/2015",
        lastUpdate: "28/06/2019",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/reglamentos/12.pdf",
        pdfMobile: "",
        word: "",
      },
      {
        id: 13,
        nameDoc:
          "Reglamento de Adquisiciones, Arrendamientos y Servicios del Instituto Tlaxcalteca de Elecciones",
        post: "31/10/2019",
        lastUpdate: "11/11/2020",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/reglamentos/13.pdf",
        pdfMobile: "",
        word: "",
      },
      {
        id: 14,
        nameDoc:
          "Reglamento del Instituto Tlaxcalteca de Elecciones en Materia de Archivos",
        post: "29/03/2022",
        lastUpdate: "Sin reforma",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/reglamentos/14.pdf",
        pdfMobile: "",
        word: "",
      },
      {
        id: 15,
        nameDoc:
          "Reglamento del Órgano Interno de Control del Instituto Tlaxcalteca de Elecciones",
        post: "17/03/2021",
        lastUpdate: "Sin reforma",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/reglamentos/15.pdf",
        pdfMobile: "",
        word: "",
      },
      {
        id: 16,
        nameDoc:
          "Reglamento para la Constitución de los Partidos Políticos Locales ante el Instituto Tlaxcalteca de Elecciones",
        post: "30/11/2015",
        lastUpdate: "30/11/2021",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/reglamentos/16.pdf",
        pdfMobile: "",
        word: "",
      },
      {
        id: 17,
        nameDoc:
          "Reglamento para el Registro de Candidaturas Independientes del Instituto Tlaxcalteca de Elecciones",
        post: "27/11/2015",
        lastUpdate: "Sin reforma",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/reglamentos/17.pdf",
        pdfMobile: "",
        word: "",
      },
      {
        id: 18,
        nameDoc:
          "Reglamento de Asistencia Técnica, Jurídica y Logística a las Comunidades que realizan elecciones de Presidentes de Comunidad por el Sistema de Usos y Costumbres",
        post: "",
        lastUpdate: "",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/reglamentos/18.pdf",
        pdfMobile: "",
        word: "",
      },
    ],
  },
  {
    id: 5,
    nameButton: "compendio",
    titleButton: "Compendio Legislativo Electoral",
    children: [
      {
        id: 1,
        nameDoc: "Portada",
        post: "",
        lastUpdate: "",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/compendio/1.pdf",
        pdfMobile: "",
        word: "",
      },
      {
        id: 2,
        nameDoc: "Constitución Local",
        post: "",
        lastUpdate: "",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/compendio/2.pdf",
        pdfMobile: "",
        word: "",
      },
      {
        id: 3,
        nameDoc: "LIPEET",
        post: "",
        lastUpdate: "",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/compendio/3.pdf",
        pdfMobile: "",
        word: "",
      },
      {
        id: 4,
        nameDoc: "LPPET",
        post: "",
        lastUpdate: "",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/compendio/4.pdf",
        pdfMobile: "",
        word: "",
      },
      {
        id: 5,
        nameDoc: "LMIMEET",
        post: "",
        lastUpdate: "",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/compendio/5.pdf",
        pdfMobile: "",
        word: "",
      },
      {
        id: 6,
        post: "",
        lastUpdate: "",
        nameDoc: "Delitos Electorales",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/compendio/6.pdf",
        pdfMobile: "",
        word: "",
      },
      {
        id: 7,
        post: "",
        lastUpdate: "",
        nameDoc: "Reglamentos y Lineamientos",
        pdf: "https://itetlax.org.mx/assets/pdf/normatividad/compendio/7.pdf",
        pdfMobile: "",
        word: "",
      },
    ],
  },
];
